import React from 'react'

import { IPatientAllData } from '../../types/patient.type'
import { AxiosError } from 'axios'
import epharmacyService from '../../services/epharmacy.service'

interface IUseWidgetsProps {
	initial?: boolean
  }

export interface IUseWidgets{
	data?: IPatientAllData
	error?: any
	loading?: boolean
	getData: (limit?: number, offset?: number, filter?: any, callBack?: () => void) => void
  }

let RECURRING_COUNT = 0

const useWidgets = (props: IUseWidgetsProps): IUseWidgets => {
	const [data, setData] = React.useState<any>()
	const [error, setError] = React.useState<any>()
	const [loading, setLoading] = React.useState(false)
	
	const getData = async (limit = 1000, offset = 0, filter?: any, callBack?: () => void) => {
	  try {
		setError(undefined)
		setLoading(true)
		const response: any = await epharmacyService.getAllWidgets(limit, offset, filter)
		setData(response.data)
		setLoading(false)
		RECURRING_COUNT = 0
	  } catch (e) {
		const error = e as AxiosError
		setError(error)
		if (error.response?.status === 401) {
		  if (RECURRING_COUNT < 2) {
			getData(limit, offset, callBack)
			RECURRING_COUNT++
		  }
		  return
		}
		setLoading(false)
		console.log('ERROR', error.response)
	  } finally {
		console.log('EXIT GET DATA')
	  }
	}
  
	React.useEffect(() => {
	  if (props.initial) getData()
	}, [])
  
	return {
	  data,
	  error,
	  loading,
	  getData
	}
}

export default useWidgets