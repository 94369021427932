import React, { FC } from 'react'

import { ConnectedFormGroup } from '../FormElements'
import { Flex } from '@chakra-ui/core'
import { Form, Formik } from 'formik'
import { InputProps } from '@chakra-ui/core/dist/Input'
import { Search, XCircle } from 'react-feather'

type SearchBarProps = InputProps & {
  handleSearch: (value: string) => void
  handleReset: () => void
}

const SearchBar: FC<SearchBarProps> = ({ handleSearch, handleReset }) => {
  const [value, setValue] = React.useState('')
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  return (
    <Flex width="100%" height="40px" alignItems="center" justifyContent="space-between">
      <Formik initialValues={{ search: '' }} onSubmit={() => {}}>
        <Form style={{ width: '100%' }}>
          <ConnectedFormGroup
            icon={Search}
            name="search"
            placeholder="Search Here..."
            fontSize={12}
            color="#8e8e8e"
            paddingLeft="40px"
            borderColor="#8e8e8e"
            bg="accent.20"
            iconPosition="right"
            iconColor="#8e8e8e"
            onChange={(e: any) => {
              setValue(e.target.value)
            }}
            value={value}
            onReset={handleReset}
            onIconClick={() => {
              handleSearch(value)
              setIsSubmitted(!!value)
            }}
            mb={0}
            clearIcon={isSubmitted}
            onClearIconClick={() => {
              setIsSubmitted(false)
              handleSearch("")
              setValue("")
            }}
          />
        </Form>
      </Formik>
    </Flex>
  )
}

export default SearchBar
