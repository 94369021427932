import React from 'react'

import { IDoctorAllData } from '../../types/servicesCatalog.type'
import serviceCatalogService from '../../services/serviceCatalog.service'

interface IUseDoctorsProps {
  initial: boolean
}

export interface IUseDoctors {
  data?: IDoctorAllData
  error?: any
  loading?: boolean
  getData: (limit?: number, offset?: number, filter?: any, callBack?: () => void) => void
}

const useDoctors = (props: IUseDoctorsProps): IUseDoctors => {
  const [data, setData] = React.useState<any>({})
  const [error, setError] = React.useState<any>()
  const [loading, setLoading] = React.useState(false)

  const getData = async (limit = 1000, offset = 0, filter?: any, callBack?: () => void) => {
    try {
      setError(undefined)
      setLoading(true)

      let response = await serviceCatalogService.getAllDoctors(
        limit === 0 ? 10 : limit,
        offset,
        filter
      )
      setData(response.data)

      setLoading(false)
      if (callBack) callBack()
    } catch (error) {
      setLoading(false)
      setError(error)
      console.log('ERROR', error)
    }
  }

  React.useEffect(() => {
    if (props.initial) getData()
  }, [])

  return {
    data,
    error,
    loading,
    getData
  }
}

export default useDoctors
