import React from 'react'
import { css, Global } from '@emotion/core'
import { CSSReset, ThemeProvider as EmotionThemeProvider } from '@chakra-ui/core'

import { theme } from '../theme'

type ThemeProviderProps = {
  children: React.ReactNode
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  return (
    <EmotionThemeProvider theme={theme}>
      <CSSReset />
      <Global
        styles={css`
          * {
            font-family: ${theme.fonts.body};
          }
          body {
            background: ${theme.colors.background};

            .active-nav-link {
              background: linear-gradient(to left, #eb1132, #f89419);
              color: white;
              .icon-wrap svg {
                stroke: white;
              }
            }
            .active-bottom-nav-link {
              border-top-width: 3px;
              border-color: #f89419;
              color: #f89419;
              .icon-wrap svg {
                stroke: #f89419;
              }
            }
          }
        `}
      />
      {children}
    </EmotionThemeProvider>
  )
}

export default ThemeProvider
