import React from 'react'

import { IAppointmentTypeAllData } from '../../types/appointment.type'
import appointmentService from '../../services/appointment.service'

interface IUseAppointmentTypeProps {}

export interface IUseAppointmentType {
  data?: IAppointmentTypeAllData
  error?: any
  loading?: boolean
  getData: () => void
}

const useAppointmentType = (props: IUseAppointmentTypeProps): IUseAppointmentType => {
  const [data, setData] = React.useState({})
  const [error, setError] = React.useState<any>()
  const [loading, setLoading] = React.useState(false)

  const getData = async () => {
    try {
      setError(undefined)
      setLoading(true)

      let response = await appointmentService.getType()
      setData(response.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(error)
      console.log('ERROR', error)
    }
  }

  return {
    data,
    error,
    loading,
    getData
  }
}

export default useAppointmentType
