import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { useAppContext } from '../context/AppProvider'

export interface RouteType extends RouteProps {
  component: any
  title: string
  props?: { [key: string]: any }
  permissionLevel?: string[]
}

const PublicRoute = ({ component: Component, ...rest }: RouteType) => {
  const { userRoles } = useAppContext()

  const hasAccess = React.useMemo(() => {
    if (rest.permissionLevel) {
      for (const iterator of rest.permissionLevel) {
        if (userRoles.includes(iterator)) {
          return true
          break
        }
      }
    }
    return false
  }, [rest.permissionLevel, userRoles])

  if (hasAccess)
    return (
      <Route exact={true} {...rest}>
        <Component {...rest.props} />
      </Route>
    )
  return null
}

export default PublicRoute
