import React from 'react'

import { IAppointmentReasonAllData } from '../../types/appointment.type'
import appointmentService from '../../services/appointment.service'

interface IUseAppointmentReasonsProps {
  initial: boolean
}

export interface IUseAppointmentReasons {
  data?: IAppointmentReasonAllData
  error?: any
  loading?: boolean
  getData: () => void
}

const useAppointmentReasons = (props: IUseAppointmentReasonsProps): IUseAppointmentReasons => {
  const [data, setData] = React.useState({})
  const [error, setError] = React.useState<any>()
  const [loading, setLoading] = React.useState(false)

  const getData = async () => {
    try {
      setError(undefined)
      setLoading(true)

      let response = await appointmentService.getReasons()
      setData(response.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(error)
      console.log('ERROR', error)
    }
  }

  React.useEffect(() => {
    if (props.initial) getData()
  }, [props.initial])

  return {
    data,
    error,
    loading,
    getData
  }
}

export default useAppointmentReasons
