import * as React from 'react'

type PageNotFoundProps = {}

const PageNotFound: React.FC<PageNotFoundProps> = () => {
  return (
    <> Page Not Found component here</>
  )
}

export default PageNotFound