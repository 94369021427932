import React from 'react'

import { IPaginationOption } from '../../types/pagination.type'
import patientService from '../../services/patient.service'
import { IPatientAllData } from '../../types/patient.type'
import { AxiosError } from 'axios'

interface IUsePatientsProps {
  initial?: boolean
}

export interface IUsePatients {
  data?: IPatientAllData
  error?: any
  loading?: boolean
  getData: (limit?: number, offset?: number, filter?: any, callBack?: () => void) => void
}

let RECURRING_COUNT = 0

const usePatients = (props: IUsePatientsProps): IUsePatients => {
  const [data, setData] = React.useState<any>()
  const [error, setError] = React.useState<any>()
  const [loading, setLoading] = React.useState(false)

  const getData = async (limit = 1000, offset = 0, filter?: any, callBack?: () => void) => {
    try {
      setError(undefined)
      setLoading(true)
      const response: any = await patientService.getAll(limit, offset, filter)
      setData(response.data)
      setLoading(false)
      RECURRING_COUNT = 0
    } catch (e) {
      const error = e as AxiosError
      setError(error)
      if (error.response?.status === 401) {
        if (RECURRING_COUNT < 2) {
          await patientService.refreshToken()
          getData(limit, offset, callBack)
          RECURRING_COUNT++
        }
        return
      }
      setLoading(false)
      console.log('ERROR', error.response)
    } finally {
      console.log('EXIT GET DATA')
    }
  }

  React.useEffect(() => {
    if (props.initial) getData()
  }, [])

  return {
    data,
    error,
    loading,
    getData
  }
}

export default usePatients
