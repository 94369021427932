import { lazy } from 'react'
import { RouteProps } from 'react-router'

import { ROLES } from '../constants'

export interface PrivateRouteObject extends RouteProps {
  exact: boolean
  path: string
  breadcrumb: string
  component: any
  title: string
  props?: { [key: string]: any }
  permissionLevel?: string[]
}

const Enquiry = lazy(() => import('../containers/Enquiry'))
const EnquiryDetail = lazy(() => import('../containers/EnquiryDetail'))
const Customer = lazy(() => import('../containers/Customer'))
const PatientInfo = lazy(() => import('../containers/PatientInfo'))
const Appointment = lazy(() => import('../containers/Appointment'))
const Dashboard = lazy(() => import('../containers/Dashboard'))
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'))
const Login = lazy(() => import('../containers/Login'))
const Doctor = lazy(() => import('../containers/Doctor'))
const ProviderManagement = lazy(() => import('../containers/ProviderManagement'))
const ClinicFacilityManagement = lazy(() => import('../containers/ClinicFacilityManagement'))
const PackageManagement = lazy(() => import('../containers/Packages'))
const ProductManagement = lazy(() => import('../containers/Products'))
const AddPackage = lazy(() => import('../containers/AddPackage'))
const ClinicBranchFacilityManagement = lazy(
  () => import('../containers/ClinicBranchFacilityManagement')
)
const PatientDetails = lazy(() => import('../containers/OnboardingPatientDetails'))
const AddDoctor = lazy(() => import('../containers/AddDoctor'))
const AddFacility = lazy(() => import('../containers/AddFacility'))
const AddEnquiry = lazy(() => import('../containers/AddEnquiry'))
const AddClinicalBranch = lazy(() => import('../containers/AddClinicalBranch'))
const AddProduct = lazy(() => import('../containers/AddProduct'))
const AddFacilityService = lazy(() => import('../containers/AddFacilityService'))
const AddFacilityPackage = lazy(() => import('../containers/AddFacilityPackage'))
const AddAppointment = lazy(() => import('../containers/AddAppointment'))
const ResetPassword = lazy(() => import('../containers/ResetPassword'))
const ViewCustomer = lazy(() => import('../containers/Customer/ViewCustomer'))
const Mails = lazy(() => import('../containers/Mails'))
const MailDetails = lazy(() => import('../containers/MailDetails'))
const Search = lazy(() => import('../containers/Search'))
const HomePage = lazy(() => import('../containers/Homepage'))
const EpharmacyTable = lazy(() => import('../containers/EpharmacyTable'))
const EpharmacyCreate = lazy(() => import('../containers/EpharmacyCreate'))
const Facility = lazy(() => import('../containers/Facility'))
const Package = lazy(() => import('../containers/Package'))
const Product = lazy(() => import('../containers/Product'))

const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    path: '/',
    breadcrumb: 'Dashboard',
    component: HomePage,
    title: 'Dashboard',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/dashboard',
    breadcrumb: 'Dashboard',
    component: Dashboard,
    title: 'Dashboard',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/search',
    breadcrumb: 'Search',
    component: Search,
    title: 'Search',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/appointment',
    breadcrumb: 'Appointment Management',
    component: Appointment,
    title: 'Appointment Management',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.B2B_AND_B2B2C,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/appointment/new/create',
    breadcrumb: 'Add Appointment',
    component: AddAppointment,
    title: 'Add Appointment',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/appointment/:id',
    breadcrumb: 'Appointment Details',
    component: PatientInfo,
    title: 'Appointment Details',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO,
      ROLES.B2B_AND_B2B2C
    ]
  },
  {
    exact: true,
    path: '/customer',
    breadcrumb: 'Customer Management',
    component: Customer,
    title: 'Customer Management',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/enquiry',
    breadcrumb: 'Enquiry Management',
    component: Enquiry,
    title: 'Enquiry Management',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/enquiry/edit/:id',
    breadcrumb: 'Enquiry Edit',
    component: AddEnquiry,
    title: 'Enquiry Edit',
    props: {
      isEdit: true
    },
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/enquiry/:id',
    breadcrumb: 'Enquiry Detail',
    component: EnquiryDetail,
    title: 'Enquiry Detail',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/customer/create/new',
    breadcrumb: 'Create Patient',
    component: PatientDetails,
    title: 'Create Patient',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/customer/:id',
    breadcrumb: 'View Customer',
    component: ViewCustomer,
    title: 'View Customer',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/customer/edit/:id',
    breadcrumb: 'Edit Customer',
    component: PatientDetails,
    title: 'Edit Customer',
    props: {
      isEdit: true
    },
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/service-and-product/1/providers-management/',
    breadcrumb: 'Management',
    component: ProviderManagement,
    title: 'Doctor',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/service-and-product/1/providers-management/:id',
    breadcrumb: 'Management',
    component: ProviderManagement,
    title: 'Doctor',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/service-and-product/1/clinic-management/clinics-branches',
    breadcrumb: 'Clinics Branch Management',
    component: ClinicBranchFacilityManagement,
    title: 'Clinics Branch Management',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/service-and-product/1/clinic-management/clinics',
    breadcrumb: 'Clinics Management',
    component: ClinicFacilityManagement,
    title: 'Clinics Management',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/service-and-product/2/packages',
    breadcrumb: 'Package Management',
    component: PackageManagement,
    title: 'Package Management',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/service-and-product/2/packages-new',
    breadcrumb: 'Add New Package',
    component: AddPackage,
    title: 'Add New Package',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/package/edit/:id',
    breadcrumb: 'Edit Package',
    component: AddPackage,
    title: 'Edit Package',
    props: {
      isEdit: true
    },
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/package/:id',
    breadcrumb: 'View Package',
    component: Package,
    title: 'View Package',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/service-and-product/2/product/:id',
    breadcrumb: 'Product Management',
    component: ProductManagement,
    title: 'Product Management',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/service-and-product/2/:id/new',
    breadcrumb: 'Add New',
    component: AddProduct,
    title: 'Add New',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/service-and-product/1/providers-management/doctor/:id',
    breadcrumb: 'View Doctor',
    component: Doctor,
    title: 'View Doctor',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/new-doctor',
    breadcrumb: 'Add Doctor',
    component: AddDoctor,
    title: 'Add Doctor',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/service-and-product/1/providers-management/doctor/edit/:id',
    breadcrumb: 'Edit Doctor',
    component: AddDoctor,
    title: 'Edit Doctor',
    props: {
      isEdit: true
    },
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/new-facility',
    breadcrumb: 'Add Facility',
    component: AddFacility,
    title: 'Add Facility',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/new-enquiry',
    breadcrumb: 'Add Enquiry',
    component: AddEnquiry,
    title: 'Add Enquiry',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/service-and-product/1/clinic-management/clinics/edit/:id',
    breadcrumb: 'Edit Facility',
    component: AddFacility,
    title: 'Edit Facility',
    props: {
      isEdit: true
    },
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/service-and-product/1/clinic-management/clinics/:id',
    breadcrumb: 'View Facility',
    component: Facility,
    title: 'View Facility',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/new-clinic-branch',
    breadcrumb: 'Add Clinical Branch',
    component: AddClinicalBranch,
    title: 'Add Clinical Branch',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/product/edit/:id/:productId',
    breadcrumb: 'Edit Product',
    component: AddProduct,
    title: 'Edit Product',
    props: {
      isEdit: true
    },
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/product/:id',
    breadcrumb: 'Product',
    component: Product,
    title: 'Product',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/mails',
    breadcrumb: 'All Mail',
    component: Mails,
    title: 'All Mail',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/mail/:id',
    breadcrumb: 'Mail Details',
    component: MailDetails,
    title: 'Mail Details',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/epharmacy/:table',
    breadcrumb: '',
    component: EpharmacyTable,
    title: 'Epharmacy',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/epharmacy/create/:form',
    breadcrumb: '',
    component: EpharmacyCreate,
    title: 'Create',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/facility-service/new',
    breadcrumb: 'Add New',
    component: AddFacilityService,
    title: 'Add New',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/facility-service/edit/:id',
    breadcrumb: 'Edit Facility Service',
    component: AddFacilityService,
    title: 'Edit Facility Service',
    props: {
      isEdit: true
    },
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/facility-package/new',
    breadcrumb: 'Add New',
    component: AddFacilityPackage,
    title: 'Add New',
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  },
  {
    exact: true,
    path: '/facility-package/edit/:id',
    breadcrumb: 'Edit Facility Package',
    component: AddFacilityPackage,
    title: 'Edit Facility Package',
    props: {
      isEdit: true
    },
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.DEMO
    ]
  }
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    title: 'Login',
    path: '/',
    component: Login
  },
  {
    exact: true,
    title: 'Login',
    path: '/login',
    component: Login
  },
  {
    exact: true,
    title: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    exact: true,
    title: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword
  }
]

export { PUBLIC_ROUTES, PRIVATE_ROUTES }
