import { theme as chakraTheme } from '@chakra-ui/core'
import images from './images'

const theme = {
  ...chakraTheme,
  fonts: {
    body: 'Gotham Bold, sans-serif',
    mono: 'Gotham Medium',
    heading: 'Gotham Book'
  },
  colors: {
    ...chakraTheme.colors,
    background: '#efefef',
    dimText: '#6a6a6a',
    accent: {
      20: '#ffffff',
      50: '#707070'
    },
    brand: {
      500: '#ff5700',
      300: '#f89419',
      700: '#ffffff'
    },
    success: {
      50: '##ffffff',
      100: '#c3ebd7',
      200: '#a0dcbf',
      300: '#7ccda7',
      400: '#59bf8e',
      500: '#40a674',
      600: '#30815a',
      700: '#205c40',
      800: '#0e3825',
      900: '#001509'
    }
  },
  boxShadow: '0px 32px 64px #ffffff',
  border: '#707070',
  hoverBackground: 'rgba(0,0,0,0.05)',
  boxShadowLight: '0 2px 4px rgba(0, 0, 0, 0.25)',
  boxShadowMedium: '0 1px 2px 0 rgba(0, 0, 0, 0.17)',
  gridGutter: 1 // rem - taken from Chakra UI space scale https://chakra-ui.com/theme#spacing
}

export { images, theme }
