import React from 'react'
import {
  AlertDialog as ChakraAlert,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  AlertDialogFooter
} from '@chakra-ui/core'

interface IAlertDialogProps {
  isOpen: boolean
  onClose: () => void
  title: string
  description: string
}

const AlertDialog: React.FC<IAlertDialogProps> = (props) => {
  const cancelRef: any = React.useRef()

  return (
    <ChakraAlert
      isCentered
      isOpen={props.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={props.onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {props.title}
          </AlertDialogHeader>

          <AlertDialogBody>{props.description}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} bg="brand.300" color="white" onClick={props.onClose}>
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlert>
  )
}

export default AlertDialog
