import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'

const MotionLink = motion.custom(NavLink)

type MenuItemProps = {}

export const MenuItem = styled(MotionLink)<MenuItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%
`
