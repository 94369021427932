import React from 'react'
import {
  FlexProps,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Flex,
  Text,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button
} from '@chakra-ui/core'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'

type IPopoverModalNotificationProps = FlexProps & {}

const PopoverModalNotification: React.FC<IPopoverModalNotificationProps> = ({ children }) => {
  const history = useHistory()
  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Flex borderBottom="1px solid #ccc" padding="5px 0px">
            <Text fontSize="sm">Some Item</Text>
          </Flex>
          <Flex borderBottom="1px solid #ccc" padding="5px 0px">
            <Text fontSize="sm">Some Item</Text>
          </Flex>
          <Flex borderBottom="1px solid #ccc" padding="5px 0px">
            <Text fontSize="sm">Some Item</Text>
          </Flex>
          <Flex borderBottom="1px solid #ccc" padding="5px 0px">
            <Text fontSize="sm">Some Item</Text>
          </Flex>
          <Flex padding="5px 0px" justifyContent="center" alignItems="center">
            <Text fontSize="sm" onClick={() => history.push('/Mails')}>
              View All{' '}
            </Text>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default PopoverModalNotification
