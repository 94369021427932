import React from 'react'

import { IGetAllClinic } from '../../types/callCenter.type'
import callCenterService from '../../services/callCenter.service'

interface IUseCliniciansProps {
  initial: boolean
}

export interface IUseClinicians {
  data?: IGetAllClinic
  error?: any
  loading?: boolean
  getData: (limit?: number, offset?: number, filter?: any, callBack?: () => void) => void
}

const useClinicians = (props: IUseCliniciansProps): IUseClinicians => {
  const [data, setData] = React.useState<any>({})
  const [error, setError] = React.useState<any>()
  const [loading, setLoading] = React.useState(false)

  const getData = async (limit = 1000, offset = 0, filter?: any, callBack?: () => void) => {
    try {
      setError(undefined)
      setLoading(true)

      let response = await callCenterService.getAllClinic(
        limit === 0 ? 10 : limit,
        offset === 0 ? 1 : offset,
        filter
      )
      setData(response.data)

      setLoading(false)
      if (callBack) callBack()
    } catch (error) {
      setLoading(false)
      setError(error)
      console.log('ERROR', error)
    }
  }

  React.useEffect(() => {
    if (props.initial) getData()
  }, [])

  return {
    data,
    error,
    loading,
    getData
  }
}

export default useClinicians
