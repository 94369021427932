import { appointmentAxios } from './axios'
import {
  IAppointmentAllData,
  IAppointmentData,
  IPaymentModeAllData,
  IVisitTypeAllData,
  IAppointmentTypeAllData,
  IAppointmentReasonAllData,
  IGetProviderType,
  IGetStatistics
} from '../types/appointment.type'

import moment from 'moment'

const DATE_FORMAT = 'YYYY-MM-DD'

class AppointmentDataService {
  getAll(limit = 10, offset = 0, filter?: any) {
    let params = {
      limit,
      offset
    }
    if (filter) params = { ...params, ...filter }

    return appointmentAxios.get<IAppointmentData>('/appointment', {
      params
    })
  }
  getByPatientId(id: string) {
    return appointmentAxios.get<Array<IAppointmentAllData>>(`/appointment?patient_id=${id}`)
  }
  getReason(id: string) {
    return appointmentAxios.get<Array<IAppointmentAllData>>(`/appointment-reason?id=${id}`)
  }
  getPaymentMode() {
    return appointmentAxios.get<Array<IPaymentModeAllData>>(`/payment-mode`)
  }
  getVisitType() {
    return appointmentAxios.get<Array<IVisitTypeAllData>>(`/visit-type`)
  }
  getReasons() {
    return appointmentAxios.get<Array<IAppointmentReasonAllData>>(`/appointment-reason`)
  }
  getType() {
    return appointmentAxios.get<Array<IAppointmentTypeAllData>>(`/appointment-type`)
  }
  getById(id: string) {
    return appointmentAxios.get<Array<IAppointmentAllData>>(`/appointment/${id}`)
  }
  getStatus() {
    return appointmentAxios.get<Array<IAppointmentAllData>>(`/status`)
  }
  getProviderType() {
    return appointmentAxios.get<IGetProviderType>(`/provider-type`)
  }
  update(id: string, data: any) {
    return appointmentAxios.put<Array<IAppointmentAllData>>(`/appointment/${id}`, data)
  }
  create(data: any) {
    return appointmentAxios.post<Array<IAppointmentAllData>>(`/appointment`, data)
  }
  getStatistic(start_date: string, end_date: string) {
    const params = {
      start_date: moment(start_date, DATE_FORMAT).toISOString(),
      end_date: moment(end_date, DATE_FORMAT).toISOString()
    }
    return appointmentAxios.get<IGetStatistics>('/statistics', {
      params
    })
  }
}
export default new AppointmentDataService()
