import { callCenterAxios } from './axios'
import { IGetAllClinic } from '../types/callCenter.type'
class CallCenterService {
  getAllClinic(limit = 1000, offset = 1, filter?: any) {
    const formData = new FormData()
    formData.append('limit', String(limit))
    formData.append('page', String(offset))

    if (filter) {
      Object.keys(filter).forEach((key) => {
        formData.append(key, filter[key])
      })
    }
    return callCenterAxios.post<Array<IGetAllClinic>>(
      `/clinics/locations/clinic-location-list`,
      formData
    )
  }
}
export default new CallCenterService()
