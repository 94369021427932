import Keycloak from 'keycloak-js'

//keycloak init options
let initOptions = {
  url: process.env.REACT_APP_KEY_CLOAK_URL || '',
  realm: process.env.REACT_APP_KEY_CLOAK_RELAM || '',
  clientId: process.env.REACT_APP_KEY_CLOAK_CLIENT_ID || '',
  onLoad: 'login-required'
}
export let keycloak = Keycloak(initOptions)
