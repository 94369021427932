import * as React from 'react'
import patientService from '../../services/patient.service'
import serviceCatalogService from '../../services/serviceCatalog.service'
import appointmentService from '../../services/appointment.service'
import useFacilities, { IUseFacilities } from '../../hooks/services/useFacilities'
import useDoctors, { IUseDoctors } from '../../hooks/services/useDoctors'
import usePrescriptions, { IUsePrescriptions } from '../../hooks/epharmacy/usePrescriptions'
import useVendors, { IUseVendors } from '../../hooks/epharmacy/useVendors'
import useMedicines, { IUseMedicines } from '../../hooks/epharmacy/useMedicines'
import useOrders, { IUseOrders } from '../../hooks/epharmacy/useOrders'
import useWidgets, { IUseWidgets } from '../../hooks/epharmacy/useWidgets'
import useInventory, { IUseInventory } from '../../hooks/epharmacy/useInventory'
import usePatients, { IUsePatients } from '../../hooks/patient/usePatients'
import usePaymentMode, { IUsePaymentMode } from '../../hooks/appointment/usePaymentMode'
import useVisitType, { IUseVisitType } from '../../hooks/appointment/useVisitType'
import useAppointmentType, { IUseAppointmentType } from '../../hooks/appointment/useAppointmentType'
import useAppointmentReasons, {
  IUseAppointmentReasons
} from '../../hooks/appointment/useAppointmentReasons'
import AlertDialog from '../../components/AlertDialog'
import { keycloak } from '../../services/keycloak'
import useClinicians, { IUseClinicians } from '../../hooks/callCenter/useClinicians'
import {
  IDoctorType,
  IOperationalStatus,
  IWorkBook,
  ICategory,
  IFaith
} from '../../types/servicesCatalog.type'
import { ROLES, PERMISSION_LEVEL } from '../../constants'
import { IRenamesWorkBook } from './AppProviderType'

type IAlertConfiguration = {
  isOpen: boolean
  title: string
  description: string
}

type AppContextType = {
  drawerOpen: boolean
  toggleDrawer: () => void
  patientStatus: any
  appointmentStatus: any
  genders: any
  doctorsLicence: any
  doctorsPracticeType: any
  doctorsGenders: any
  martialStatus: any
  facilities: IUseFacilities
  doctors: IUseDoctors
  clinicians: IUseClinicians
  patients: IUsePatients
  prescriptions: IUsePrescriptions
  vendors: IUseVendors
  widgets: IUseWidgets
  orders: IUseOrders
  medicines: IUseMedicines
  inventory: IUseInventory
  paymentMode: IUsePaymentMode
  visitType: IUseVisitType
  appointmentType: IUseAppointmentType
  appointmentReasons: IUseAppointmentReasons
  onAlertOpen: React.Dispatch<React.SetStateAction<IAlertConfiguration>>
  userInfo: UserInformation
  doctorType: IDoctorType[]
  operationalStatus: any
  facilityType: any
  facilityStatus: any
  packagesStatus: any
  packageCategory: any
  workBooks: IWorkBook[]
  userRoles: string[]
  userDetailsLoading: boolean
  userPermissions: string[]
  getFacilityCategories: any
  facilityCategories: ICategory[]
  faiths: IFaith[]
}

type UserInformation = {
  email: string
  email_verified: boolean
  family_name: string
  given_name: string
  name: string
  preferred_username: string
  sub: string
  loading: boolean
}

const defaultUserInformation: UserInformation = {
  email: '',
  email_verified: false,
  family_name: '',
  given_name: '',
  name: '',
  preferred_username: '',
  sub: '',
  loading: false
}

const apiMock = {
  getData: () => null,
  loading: false,
  error: null,
  data: {
    available_resultset_size: 0,
    limit: 0,
    offset: 0,
    returned_resultset: [],
    returned_resultset_size: 0
  }
}

const callCenterApiMock = {
  getData: () => null,
  loading: false,
  error: null,
  data: {
    limit: 0,
    page: 0,
    result: [],
    total: 0
  }
}

export const AppContext = React.createContext<AppContextType>({
  drawerOpen: false,
  toggleDrawer: () => null,
  patientStatus: {},
  appointmentStatus: {},
  genders: {},
  doctorsPracticeType: {},
  doctorsGenders: {},
  martialStatus: {},
  doctorsLicence: {},
  facilities: apiMock,
  doctors: apiMock,
  clinicians: callCenterApiMock,
  patients: apiMock,
  prescriptions: apiMock,
  vendors: apiMock,
  widgets: apiMock,
  orders: apiMock,
  medicines: apiMock,
  inventory: apiMock,
  paymentMode: apiMock,
  visitType: apiMock,
  appointmentType: apiMock,
  appointmentReasons: apiMock,
  onAlertOpen: () => null,
  userInfo: defaultUserInformation,
  doctorType: [],
  operationalStatus: {},
  facilityType: {},
  facilityStatus: {},
  packagesStatus: {},
  workBooks: [],
  packageCategory: {},
  userRoles: [],
  userDetailsLoading: false,
  userPermissions: [],
  getFacilityCategories: () => null,
  facilityCategories: [],
  faiths: []
})

export const useAppContext = () => React.useContext(AppContext)

const AppProvider: React.FC<{}> = ({ children }) => {
  const [userInfo, setUserInfo] = React.useState<UserInformation>(defaultUserInformation)
  const [alertConfiguration, setAlertConfiguration] = React.useState<IAlertConfiguration>({
    description: '',
    isOpen: false,
    title: ''
  })
  const [drawerOpen, setDrawerOpen] = React.useState(true)
  const [patientStatus, setPatientStatus] = React.useState<any>({})
  const [appointmentStatus, setAppointmentStatus] = React.useState<any>({})
  const [genders, setGenders] = React.useState<any>({})
  const [doctorsGenders, setDoctorsGenders] = React.useState<any>({})
  const [doctorsPracticeType, setDoctorsPracticeType] = React.useState<any>({})
  const [doctorsLicence, setDoctorsLicence] = React.useState<any>({})
  const [martialStatus, setMartialStatus] = React.useState<any>({})
  const [operationalStatus, setOperationalStatus] = React.useState<any>({})
  const [facilityType, setFacilityType] = React.useState<any>({})
  const [facilityStatus, setFacilityStatus] = React.useState<any>({})
  const [packagesStatus, setPackageStatus] = React.useState<any>({})
  const [doctorType, setDoctorType] = React.useState<any>([])
  const [workBooks, setWorkBooks] = React.useState<any>([])
  const [packageCategory, setPackageCategory] = React.useState<any>([])
  const [userRoles, setUserRoles] = React.useState<string[]>([])
  const [userDetailsLoading, setUserDetailsLoading] = React.useState(false)
  const [userPermissions, setUserPermissions] = React.useState<string[]>([])
  const [facilityCategories, setFacilityCategories] = React.useState([])
  const [faiths, setFaiths] = React.useState([])

  const facilities = useFacilities({ initial: false })
  const doctors = useDoctors({ initial: false })
  const clinicians = useClinicians({ initial: false })
  const patients = usePatients({
    initial: false
  })
  const prescriptions = usePrescriptions({
    initial: false
  })
  const vendors = useVendors({
    initial: false
  })
  const medicines = useMedicines({
    initial: false
  })
  const inventory = useInventory({
    initial: false
  })
  const widgets = useWidgets({
    initial: false
  })
  const orders = useOrders({
    initial: false
  })
  const paymentMode = usePaymentMode({
    initial: false
  })
  const visitType = useVisitType({
    initial: false
  })
  const appointmentType = useAppointmentType({
    initial: false
  })
  const appointmentReasons = useAppointmentReasons({
    initial: false
  })

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen)
  }

  const getPatientStatus = async () => {
    const response: any = await patientService.getStatus()
    if (response.data?.returned_resultset) {
      const payload: any = {}
      response.data?.returned_resultset.forEach((ele: any) => {
        payload[ele.id] = ele
      })
      setPatientStatus(payload)
    }
  }

  const getFacilityCategories = async () => {
    const response: any = await serviceCatalogService.getCategory()
    if (response.data?.returned_resultset) setFacilityCategories(response.data?.returned_resultset)
  }

  const getAllFaiths = async () => {
    const response: any = await serviceCatalogService.getFaiths()
    if (response.data?.returned_resultset) setFaiths(response.data?.returned_resultset)
  }

  const getAppointmentStatus = async () => {
    const response: any = await appointmentService.getStatus()
    if (response.data?.returned_resultset) {
      const payload: any = {}
      response.data?.returned_resultset.forEach((ele: any) => {
        payload[ele.id] = ele
      })
      setAppointmentStatus(payload)
    }
  }

  const getFacilityStatus = async () => {
    const response: any = await serviceCatalogService.getFacilityStatus()
    if (response.data?.returned_resultset) {
      const payload: any = {}
      response.data?.returned_resultset.forEach((ele: any) => {
        payload[ele.id] = ele?.name
      })
      setFacilityStatus(payload)
    }
  }

  const getGenderStatus = async () => {
    const response: any = await patientService.getGender()
    if (response.data?.returned_resultset) {
      const payload: any = {}
      response.data?.returned_resultset.forEach((ele: any) => {
        payload[ele.id] = ele
      })
      setGenders(payload)
    }
  }

  const getDoctorsGenderStatus = async () => {
    const response: any = await serviceCatalogService.getDoctorGender()
    if (response.data?.returned_resultset) {
      const payload: any = {}
      response.data?.returned_resultset.forEach((ele: any) => {
        payload[ele.id] = ele?.name
      })
      setDoctorsGenders(payload)
    }
  }

  const getPackageStatus = async () => {
    const response: any = await serviceCatalogService.getStatus()
    if (response.data?.returned_resultset) {
      const payload: any = {}
      response.data?.returned_resultset.forEach((ele: any) => {
        payload[ele.id] = ele?.name
      })
      setPackageStatus(payload)
    }
  }

  const getPackageCategories = async () => {
    const response: any = await serviceCatalogService.getPackageCategory()
    if (response.data?.returned_resultset) {
      const payload: any = {}
      response.data?.returned_resultset.forEach((ele: any) => {
        payload[ele.id] = ele?.name
      })
      setPackageCategory(payload)
    }
  }

  const getOperationalStatus = async () => {
    const response: any = await serviceCatalogService.getOperationalStatus()
    if (response.data?.returned_resultset) {
      const payload: any = {}
      response.data?.returned_resultset.forEach((ele: any) => {
        payload[ele.id] = ele?.name
      })
      setOperationalStatus(payload)
    }
  }

  const getFacilityType = async () => {
    const response: any = await serviceCatalogService.getFacilityType()
    if (response.data?.returned_resultset) {
      const payload: any = {}
      response.data?.returned_resultset.forEach((ele: any) => {
        payload[ele.id] = ele?.name
      })
      setFacilityType(payload)
    }
  }

  const getDoctorsType = async () => {
    const response: any = await serviceCatalogService.getDoctorType()
    if (response.data?.returned_resultset) {
      setDoctorType(response.data?.returned_resultset)
    }
  }

  const getWorkBook = async () => {
    const response = await serviceCatalogService.getWorkBook()
    if (response.data?.returned_resultset) {
      let filterWorkBooks: any = []
      const ignoreWorkBook = ['Packages']
      const renamesWorkBooks: IRenamesWorkBook[] = [
        {
          key: 'Doctor',
          newValue: 'Speciality'
        }
      ]
      response.data?.returned_resultset.forEach((row) => {
        if (!ignoreWorkBook.includes(row.description)) {
          const element = row
          const hasToReplace = renamesWorkBooks.find((book) => book.key === row.description)
          if (hasToReplace) {
            element.description = hasToReplace.newValue
          }
          filterWorkBooks.push(element)
        }
      })

      setWorkBooks(filterWorkBooks)
    }
  }

  const getDoctorsPracticeType = async () => {
    const response: any = await serviceCatalogService.getDoctorPracticeType()
    if (response.data?.returned_resultset) {
      const payload: any = {}
      response.data?.returned_resultset.forEach((ele: any) => {
        payload[ele.id] = ele?.name
      })
      setDoctorsPracticeType(payload)
    }
  }

  const getDoctorsLicence = async () => {
    const response: any = await serviceCatalogService.getDoctorLicence()
    if (response.data?.returned_resultset) {
      const payload: any = {}
      response.data?.returned_resultset.forEach((ele: any) => {
        payload[ele.id] = ele?.name
      })
      setDoctorsLicence(payload)
    }
  }

  const getMartialStatus = async () => {
    const response: any = await patientService.getMartialStatus()
    if (response.data?.returned_resultset) {
      const payload: any = {}
      response.data?.returned_resultset.forEach((ele: any) => {
        payload[ele.id] = ele
      })
      setMartialStatus(payload)
    }
  }

  const handleAlertClose = () => setAlertConfiguration((pre) => ({ ...pre, isOpen: false }))

  const setUserPermissionsLevel = (roles: string[]) => {
    if (roles.includes(ROLES.ADMIN)) {
      setUserPermissions(PERMISSION_LEVEL.ADMIN)
    } else if (roles.includes(ROLES.NON_CLINICAL_MOC_AGENTS)) {
      setUserPermissions(PERMISSION_LEVEL.NON_CLINICAL_MOC_AGENTS)
    } else if (roles.includes(ROLES.CLINICAL_OR_PHARMACY_TEAM)) {
      setUserPermissions(PERMISSION_LEVEL.CLINICAL_OR_PHARMACY_TEAM)
    } else if (roles.includes(ROLES.FINANCE_OR_COMMERCIAL)) {
      setUserPermissions(PERMISSION_LEVEL.FINANCE_OR_COMMERCIAL)
    } else if (roles.includes(ROLES.B2B_AND_B2B2C)) {
      setUserPermissions(PERMISSION_LEVEL.B2B_AND_B2B2C)
    } else if (roles.includes(ROLES.DEMO)) {
      setUserPermissions(PERMISSION_LEVEL.DEMO_USER)
    }
  }

  const getUserInfoFromKeyCloak = () => {
    if (keycloak.realmAccess?.roles) {
      setUserRoles(keycloak.realmAccess?.roles)
      setUserPermissionsLevel(keycloak.realmAccess?.roles)
    }
    keycloak.loadUserInfo().then((user: any) => {
      setUserInfo({
        email: user?.email,
        email_verified: user?.email_verified,
        family_name: user?.family_name,
        given_name: user?.given_name,
        name: user?.name,
        preferred_username: user?.preferred_username,
        sub: user?.sub,
        loading: true
      })
      setUserDetailsLoading(true)
    })
  }

  React.useEffect(() => {
    getPatientStatus()
    getGenderStatus()
    getMartialStatus()
    getAppointmentStatus()
    getUserInfoFromKeyCloak()
    getDoctorsGenderStatus()
    getDoctorsPracticeType()
    getDoctorsLicence()
    getDoctorsType()
    getOperationalStatus()
    getFacilityType()
    getFacilityStatus()
    getPackageStatus()
    getWorkBook()
    getPackageCategories()
    getAllFaiths()
  }, [])

  return (
    <AppContext.Provider
      value={{
        drawerOpen,
        toggleDrawer,
        patientStatus,
        genders,
        martialStatus,
        appointmentStatus,
        facilities,
        doctors,
        patients,
        prescriptions,
        vendors,
        inventory,
        orders,
        medicines,
        widgets,
        paymentMode,
        visitType,
        appointmentType,
        appointmentReasons,
        onAlertOpen: setAlertConfiguration,
        userInfo,
        doctorsGenders,
        clinicians,
        doctorsPracticeType,
        doctorsLicence,
        doctorType,
        operationalStatus,
        facilityType,
        facilityStatus,
        packagesStatus,
        workBooks,
        packageCategory,
        userRoles,
        userDetailsLoading,
        userPermissions,
        getFacilityCategories,
        facilityCategories,
        faiths
      }}
    >
      <AlertDialog
        isOpen={alertConfiguration.isOpen}
        onClose={handleAlertClose}
        title={alertConfiguration.title}
        description={alertConfiguration.description}
      />
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
