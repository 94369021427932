import * as React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

// import { useKeycloak } from '@react-keycloak/web'

import Navigation from './navigation'
import { useAppContext } from './context/AppProvider'
import FillLoader from './components/FillLoader'

const App: React.FC = () => {
  const { userDetailsLoading } = useAppContext()
  // const { initialized } = useKeycloak()

  if (!userDetailsLoading) return <FillLoader />

  return (
    <Router>
      <Navigation />
    </Router>
  )
}

export default App
