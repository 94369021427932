import * as React from 'react'
import { AnimatePresence } from 'framer-motion'
import { Flex } from '@chakra-ui/core'
import { useMediaQuery } from 'react-responsive'
import { ChevronUp, ChevronDown } from 'react-feather'

import { Text } from '../../../typography'
import { MenuItem, Tooltip, MenuContainer, MenuSubItem } from './styles'
import { useAppContext } from '../../../context/AppProvider'

import { SubNavItem } from '../../../constants'
import { useLocation } from 'react-router-dom'

type SideBarItemProps = {
  to: string
  mt?: number
  title: string
  color: string
  Icon: React.FC
  tooltipBg?: string
  hoverColor: string
  accentColor: string
  tooltipColor?: string
  closeOnNavigate?: boolean
  subMenus?: SubNavItem[]
  handleClick?: () => void
}
type SideBarSubItemProps = {
  to: string
  mt?: number
  title: string
  color: string
  tooltipBg?: string
  hoverColor: string
  accentColor: string
  tooltipColor?: string
  closeOnNavigate?: boolean
  subMenus?: SubNavItem[]
  handleClick?: () => void
}

type SideBarSubSubItemProps = SideBarSubItemProps & {
  variants: any
  pl: number
}

const isExactMatchPath = (path: string) =>
  window.location.pathname.toLowerCase().includes(path.toLowerCase())

const SideBarSubSubItem: React.FC<SideBarSubSubItemProps> = ({
  to,
  mt,
  color,
  title,
  tooltipBg,
  hoverColor,
  accentColor,
  handleClick,
  tooltipColor,
  subMenus,
  variants,
  pl
}) => {
  const [isVisible, setVisible] = React.useState(false)
  const { drawerOpen, toggleDrawer } = useAppContext()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const location = useLocation()

  React.useEffect(() => {
    if (isExactMatchPath(to)) setVisible(true)
    else setVisible(false)
  }, [location])

  const handleMenuClick = React.useCallback(
    (e) => {
      if (subMenus?.length) {
        setVisible((pre) => !pre)
        e.preventDefault()
        return
      }
      handleClick && handleClick()
    },
    [handleClick, subMenus, isVisible]
  )
  return (
    <>
      <MenuSubItem
        mt={0}
        height="30px"
        to={to}
        color={color}
        hoveraccent={accentColor}
        onClick={handleMenuClick}
        variants={variants}
        hovercolor={hoverColor}
        accentcolor={accentColor}
        activeClassName="active-sub-sub-nav-link"
      >
        <Flex
          mt={mt ? mt : 0}
          height="30px"
          alignItems="center"
          justifyContent="center"
          className="sidebar-nav-item-wrapper"
          w="100%"
        >
          <Flex flexGrow={1} pl={pl}>
            <AnimatePresence>
              {drawerOpen && (
                <Text
                  fontSize={11}
                  textAlign="left"
                  color={color}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, pointerEvents: 'none' }}
                >
                  {title}
                </Text>
              )}
            </AnimatePresence>
          </Flex>
          {!drawerOpen && !isTabletOrMobile && (
            <Tooltip bg={tooltipBg || 'gray.800'} className="sidebar-tooltip">
              <Text fontSize={13} color={tooltipColor || '#000000'}>
                {title}
              </Text>
            </Tooltip>
          )}
          {!!subMenus?.length && (
            <Flex className="icon-wrap" mx={3}>
              {isVisible ? <ChevronUp size={12} /> : <ChevronDown size={12} />}
            </Flex>
          )}
        </Flex>
      </MenuSubItem>
      {isVisible &&
        subMenus?.map((menu) => (
          <SideBarSubSubItem
            accentColor={accentColor}
            color={color}
            hoverColor={hoverColor}
            title={menu.title}
            to={menu.to}
            variants={variants}
            handleClick={handleClick}
            mt={mt}
            subMenus={menu.subMenus}
            tooltipBg={tooltipBg}
            tooltipColor={tooltipColor}
            pl={8}
          />
        ))}
    </>
  )
}

const SideBarSubItem: React.FC<SideBarSubItemProps> = ({
  to,
  mt,
  color,
  title,
  tooltipBg,
  hoverColor,
  accentColor,
  handleClick,
  tooltipColor,
  subMenus
}) => {
  const [isVisible, setVisible] = React.useState(false)
  const location = useLocation()

  React.useEffect(() => {
    if (isExactMatchPath(to)) setVisible(true)
    else setVisible(false)
  }, [location])

  const { drawerOpen, toggleDrawer } = useAppContext()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  const variants = {
    open: {
      x: 0,
      transition: {
        x: { stiffness: 200, velocity: -100 }
      }
    },
    closed: {
      x: isTabletOrMobile ? -50 : 0,
      transition: {
        x: { stiffness: 200 }
      }
    }
  }

  const handleMenuClick = React.useCallback(
    (e) => {
      if (subMenus?.length) {
        setVisible((pre) => !pre)
        e.preventDefault()
        return
      }
      handleClick && handleClick()
    },
    [handleClick, subMenus, isVisible]
  )

  return (
    <>
      <MenuSubItem
        mt={mt}
        to={to}
        color={color}
        hoveraccent={accentColor}
        variants={variants}
        onClick={handleMenuClick}
        hovercolor={hoverColor}
        accentcolor={accentColor}
        activeClassName="active-sub-nav-link"
      >
        <Flex
          mt={mt ? mt : 0}
          height="50px"
          alignItems="center"
          justifyContent="center"
          className="sidebar-nav-item-wrapper"
          w="100%"
        >
          <Flex flexGrow={1} pl={4}>
            <AnimatePresence>
              {drawerOpen && (
                <Text
                  fontSize={12}
                  textAlign="left"
                  color={color}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, pointerEvents: 'none' }}
                >
                  {title}
                </Text>
              )}
            </AnimatePresence>
          </Flex>
          {!drawerOpen && !isTabletOrMobile && (
            <Tooltip bg={tooltipBg || 'gray.800'} className="sidebar-tooltip">
              <Text fontSize={13} color={tooltipColor || '#000000'}>
                {title}
              </Text>
            </Tooltip>
          )}
          {!!subMenus?.length && (
            <Flex className="icon-wrap" mx={3}>
              {isVisible ? <ChevronUp /> : <ChevronDown />}
            </Flex>
          )}
        </Flex>
      </MenuSubItem>
      {isVisible &&
        subMenus?.map((menu) => (
          <SideBarSubSubItem
            accentColor={accentColor}
            color={color}
            hoverColor={hoverColor}
            title={menu.title}
            to={menu.to}
            variants={variants}
            handleClick={handleClick}
            mt={mt}
            subMenus={menu.subMenus}
            tooltipBg={tooltipBg}
            tooltipColor={tooltipColor}
            pl={6}
          />
        ))}
    </>
  )
}

const SideBarItem: React.FC<SideBarItemProps> = ({
  to,
  mt,
  Icon,
  color,
  title,
  tooltipBg,
  hoverColor,
  accentColor,
  handleClick,
  tooltipColor,
  closeOnNavigate,
  subMenus
}) => {
  const [isVisible, setVisible] = React.useState(false)
  const { drawerOpen, toggleDrawer } = useAppContext()
  const location = useLocation()

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  React.useEffect(() => {
    if (isExactMatchPath(to)) setVisible(true)
    else setVisible(false)
  }, [location])

  const variants = {
    open: {
      x: 0,
      transition: {
        x: { stiffness: 200, velocity: -100 }
      }
    },
    closed: {
      x: isTabletOrMobile ? -50 : 0,
      transition: {
        x: { stiffness: 200 }
      }
    }
  }

  const handleMenuClick = React.useCallback(
    (e) => {
      if (subMenus?.length) {
        setVisible((pre) => !pre)
        e.preventDefault()
        return
      }
      if (closeOnNavigate && drawerOpen) {
        toggleDrawer()
      }
      handleClick && handleClick()
    },
    [closeOnNavigate, drawerOpen, handleClick, subMenus, isVisible]
  )

  return (
    <MenuContainer mt={mt}>
      <MenuItem
        to={to}
        color={color}
        hoveraccent={accentColor}
        variants={variants}
        hovercolor={hoverColor}
        onClick={handleMenuClick}
        accentcolor={accentColor}
        activeClassName="active-nav-link"
      >
        <Flex
          mt={mt ? mt : 0}
          height="50px"
          alignItems="center"
          justifyContent="center"
          className="sidebar-nav-item-wrapper"
          w="100%"
        >
          <Flex className="icon-wrap" mx={3}>
            <Icon />
          </Flex>
          <Flex flexGrow={1}>
            <AnimatePresence>
              {drawerOpen && (
                <Text
                  ml={4}
                  color={color}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, pointerEvents: 'none' }}
                >
                  {title}
                </Text>
              )}
            </AnimatePresence>
          </Flex>
          {!drawerOpen && !isTabletOrMobile && (
            <Tooltip bg={tooltipBg || 'gray.800'} className="sidebar-tooltip">
              <Text fontSize={13} color={tooltipColor || '#000000'}>
                {title}
              </Text>
            </Tooltip>
          )}
          {!!subMenus?.length && (
            <Flex className="icon-wrap" mx={3}>
              {isVisible ? <ChevronUp /> : <ChevronDown />}
            </Flex>
          )}
        </Flex>
      </MenuItem>
      {isVisible &&
        subMenus?.map((menu) => (
          <SideBarSubItem
            accentColor={accentColor}
            color={color}
            hoverColor={hoverColor}
            closeOnNavigate={closeOnNavigate}
            handleClick={handleClick}
            mt={mt}
            tooltipBg={tooltipBg}
            tooltipColor={tooltipColor}
            {...menu}
          />
        ))}
    </MenuContainer>
  )
}

export default SideBarItem
