import React from 'react'

import { IVisitTypeAllData } from '../../types/appointment.type'
import appointmentService from '../../services/appointment.service'

interface IUseVisitTypeProps {
  initial: boolean
}

export interface IUseVisitType {
  data?: IVisitTypeAllData
  error?: any
  loading?: boolean
  getData: () => void
}

const useVisitType = (props: IUseVisitTypeProps): IUseVisitType => {
  const [data, setData] = React.useState({})
  const [error, setError] = React.useState<any>()
  const [loading, setLoading] = React.useState(false)

  const getData = async () => {
    try {
      setError(undefined)
      setLoading(true)

      let response = await appointmentService.getVisitType()
      setData(response.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(error)
      console.log('ERROR', error)
    }
  }

  React.useEffect(() => {
    if (props.initial) getData()
  }, [props.initial])

  return {
    data,
    error,
    loading,
    getData
  }
}

export default useVisitType
