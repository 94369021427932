import * as React from 'react'
import styled from '@emotion/styled'

import { Bell, Circle, Mail, Search } from 'react-feather'
import { color, ColorProps, space, SpaceProps } from 'styled-system'
import { Flex, Icon, Image } from '@chakra-ui/core'
import { Form, Formik, FormikProps } from 'formik'
import { motion } from 'framer-motion'
import { RouteComponentProps, withRouter, useHistory } from 'react-router'
import { useMediaQuery } from 'react-responsive'

import { ConnectedSelect } from '../FormElements'
import { images, theme } from '../../theme'
import { Text } from '../../typography'
import { useAppContext } from '../../context/AppProvider'
import MotionFlex from '../MotionFlex'
import SearchBar from '../SearchBar'
import SideBarButton from '../SideBar/SideBarButton'
import PopoverModalNotification from '../PopoverModalNotification'

type HeaderProps = RouteComponentProps &
  ColorProps & {
    color?: string
    size?: number
    id?: string
    open?: boolean
    getLoggedInUser?: () => { name?: string; id: string }
  }

type CountryValues = {
  country: string
}

type HeaderContProps = SpaceProps &
  ColorProps & {
    color?: string
    open?: boolean
  }

type CounterProps = {
  count: number
  color: string
}

const NotificationsCounter: React.FC<CounterProps> = ({ count, color }) => {
  return (
    <Flex flexDirection="column">
      <Flex
        backgroundColor={color}
        height="15px"
        width="15px"
        color="#fff"
        textAlign="center"
        justify="center"
        align="center"
        borderRadius="50%"
        fontSize="12px"
        position="absolute"
        top="-10px"
        right="-10px"
      >
        {count > 9 ? '9+' : count}
      </Flex>
    </Flex>
  )
}

const HeaderCont = styled(motion.div)<HeaderContProps>`
  ${space};
  ${color};
  top: 0;
  right: 0;
  height: 75px;
  z-index: 1290;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  box-shadow: 0 1px 1px 0 ${theme.colors.brand[700]};
  left: ${(props) => (props.open ? '250px' : 0)};
  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`

const Header: React.FC<HeaderProps> = ({ ...rest }) => {
  const history = useHistory()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const { drawerOpen, toggleDrawer, userInfo } = useAppContext()

  const handleLogoClicked = () => {
    history.push(`/dashboard`)
  }

  return (
    <HeaderCont pr={4} pl={drawerOpen ? 'calc(186px + 1rem)' : '1rem'} {...rest}>
      <SideBarButton color="#eb1132" open={drawerOpen} onClick={toggleDrawer} />
      {!drawerOpen && (
        <Flex
          width={isTabletOrMobile ? '50%' : '40%'}
          align="center"
          justifyContent={isTabletOrMobile ? 'center' : 'flex-start'}
          pl={5}
          visibility={['hidden', null, 'visible']}
        >
          <Image
            cursor="pointer"
            onClick={handleLogoClicked}
            mr={5}
            width="200px"
            src={images.poneaLogo}
          />
        </Flex>
      )}
      {drawerOpen && <Flex width="30%" />}
      <Flex display={isTabletOrMobile ? 'none' : 'flex'} width={isTabletOrMobile ? 0 : '55%'}>
        <Flex
          width={drawerOpen ? '50%' : '40%'}
          alignItems="center"
          justifyContent="space-between"
          px={5}
        >
          <Formik
            initialValues={{ country: 'Kenya' }}
            onSubmit={async ({ country }, { setStatus, setSubmitting }) => {
              setStatus(null)
              try {
                setSubmitting(true)
                await console.log('country select', country)
                setSubmitting(false)
              } catch (error) {
                console.log('error', error)
              }
            }}
          >
            {({ isSubmitting, status, setFieldValue }: FormikProps<CountryValues>) => (
              <Form>
                <ConnectedSelect
                  label=""
                  placeholder="Select Country"
                  name="country"
                  mt="8px"
                  options={[
                    {
                      label: 'Kenya',
                      value: 'Kenya'
                    }
                  ]}
                  onChange={(country) => {
                    setFieldValue('country', country.target.value)
                  }}
                />
                {status && (
                  <MotionFlex initial={{ opacity: 0 }} animate={{ opacity: 1 }} mb={2} width="100%">
                    <Text textAlign="right" color="red.500">
                      {status}
                    </Text>
                  </MotionFlex>
                )}
              </Form>
            )}
          </Formik>
        </Flex>
        <Flex width="65%" marginTop="15px" mr={4}>
          <SearchBar
            handleSearch={(value) => history.push(`/search?query=${value}`)}
            handleReset={() => null}
          />
        </Flex>
      </Flex>
      <Flex
        display={['flex', null, 'none']}
        flexDirection="column"
        cursor="pointer"
        mr={5}
        position="relative"
      >
        <Search color="black" onClick={() => null} />
      </Flex>
      <PopoverModalNotification>
        <Flex flexDirection="column" cursor="pointer" mr={5} position="relative">
          <NotificationsCounter count={1} color={theme.colors.brand[500]} />
          <Bell color="black" onClick={() => null} />
        </Flex>
      </PopoverModalNotification>
      <PopoverModalNotification>
        <Flex flexDirection="column" cursor="pointer" mr={5} position="relative">
          <NotificationsCounter count={3} color={theme.colors.brand[500]} />
          <Mail color="black" />
        </Flex>
      </PopoverModalNotification>
      <Flex flexDirection="row" cursor="pointer" mr={[0, null, 5]} position="relative">
        <Flex justify="center" align="center" mr={[0, null, 2]} width="60px" height="50px">
          <Image
            width="50px"
            height="50px"
            borderRadius="50%"
            src="https://via.placeholder.com/140x100"
          />
        </Flex>
        <Flex flexDirection="column" d={['none', null, 'flex']}>
          <Text style={{ width: 'max-content', fontWeight: 'bold' }}>{userInfo.name}</Text>
          <Flex flexDirection="row" justify="center" align="center" borderRadius="50%">
            <Icon as={Circle} size="4px" color="green" />
            <Text style={{ width: 'max-content', marginLeft: '4px' }} fontSize="12px">
              Super Admin
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </HeaderCont>
  )
}

export default withRouter(Header)

Header.defaultProps = {
  bg: 'brand.700'
}
