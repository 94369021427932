import { patientAxios, setTokenPatient } from './axios'
import {
  IPatientAllData,
  IPatientData,
  IAuthToken,
  IInsurances,
  IStatistics
} from '../types/patient.type'
import {
  IStatusAllData,
  IGenderAllData,
  IMartialStatusAllData,
  IBloodGroupAllData,
  IGetCounties,
  IGetPatientCooperate
} from '../types/status.type'
import moment from 'moment'

import { DOCUMENT_TYPE } from '../constants'
const DATE_FORMAT = 'YYYY-MM-DD'

class PatientDataService {
  getAll(limit = 1000, offset = 0, filter?: any) {
    let params = {
      limit,
      offset
    }
    if (filter) params = { ...params, ...filter }

    return patientAxios.get<IPatientAllData>('/patient', {
      params
    })
  }
  getById(id: string) {
    return patientAxios.get<Array<IPatientData>>(`/patient/${id}`)
  }
  getStatistic(start_date: string, end_date: string) {
    const params = {
      start_date: moment(start_date, DATE_FORMAT).toISOString(),
      end_date: moment(end_date, DATE_FORMAT).toISOString()
    }
    return patientAxios.get<IStatistics>('/statistics', {
      params
    })
  }
  getStatus() {
    return patientAxios.get<Array<IStatusAllData>>('/status')
  }
  getCounties() {
    return patientAxios.get<IGetCounties>('/country?limit=500')
  }
  getGender() {
    return patientAxios.get<Array<IGenderAllData>>('/gender')
  }
  getMartialStatus() {
    return patientAxios.get<Array<IMartialStatusAllData>>('/marital-status')
  }
  getInsuranceCompany() {
    return patientAxios.get<IInsurances>('/insurance-company')
  }
  getBloodGroups() {
    return patientAxios.get<Array<IBloodGroupAllData>>('/blood-group')
  }
  getCorporate(patientId: string) {
    return patientAxios.get<IGetPatientCooperate>(`/patient-corporate?patient_id=${patientId}`)
  }
  async updateFile(files: File, key: 'image' | 'doc', documentType: number | string, id: string) {
    try {
      const payload = new FormData()
      payload.append('document_type_id', String(documentType))

      if (key === 'image') {
        payload.append('profile_image', files)
      }
      if (key === 'doc') {
        payload.append('profile_image', files)
      }

      await patientAxios.put<IPatientAllData>(`/patient/${id}`, payload)
    } catch (error) {
      console.log('Error')
      return error
    }
  }
  async update(id: string, data: any) {
    try {
      const patientPayload = Object.keys(data.patientInfo).length && data.patientInfo
      await this.refreshToken()
      if (patientPayload) {
        await patientAxios.put<Array<IPatientAllData>>(`/patient/${id}`, patientPayload)
      }
      const locationPayload =
        Object.keys(data.address).length > 1 && JSON.parse(JSON.stringify(data.address))
      if (locationPayload) {
        delete locationPayload.id
        await patientAxios.put<IPatientAllData>(
          `/patient-location/${data.address.id}`,
          locationPayload
        )
      }

      const lifeStylePayload =
        Object.keys(data.lifestyle).length > 1 && JSON.parse(JSON.stringify(data.lifestyle))
      if (lifeStylePayload) {
        delete lifeStylePayload.id
        await patientAxios.put<IPatientAllData>(
          `/patient-lifestyle/${data.lifestyle.id}`,
          lifeStylePayload
        )
      }

      if (data.records.profile) {
        this.updateFile(data.records.profile, 'image', DOCUMENT_TYPE.image, id)
      }
      if (data.records.document1) {
        this.updateFile(data.records.document1, 'doc', DOCUMENT_TYPE.medicalRecord, id)
      }
      if (data.records.document2) {
        this.updateFile(data.records.document2, 'doc', DOCUMENT_TYPE.medicalRecord, id)
      }
      if (data.records.document3) {
        this.updateFile(data.records.document3, 'doc', DOCUMENT_TYPE.medicalRecord, id)
      }
      if (data.records.document4) {
        this.updateFile(data.records.document4, 'doc', DOCUMENT_TYPE.medicalRecord, id)
      }
    } catch (error) {
      console.log('Error')
    }
  }
  async create(data: any) {
    const patientPayload = {
      first_name: data.first_name,
      last_name: data.last_name,
      passport_number: data.emergency.passport_number,
      dob: data.dob,
      mobile_number: '254' + data.mobile_number,
      email: data.email,
      is_dependant: data.is_dependant,
      is_insured: data.is_insured,
      gender_id: data.gender_id,
      iso_code: data.iso_code,
      id_number: data.id_number,
      blood_group_id: data.blood_group_id,
      marital_status_id: data.marital_status_id
    }
    await this.refreshToken()
    const patientResponse = await patientAxios.post<IPatientAllData>(`/patient`, patientPayload)
    const patientId = patientResponse?.data?.returned_resultset[0].id
    const locationPayload = {
      patient_id: patientId,
      address: data.address.address,
      details: data.address.details,
      latitude: '',
      longitude: '',
      state: data.address.state,
      room: data.address.room,
      postal_address: data.address.postal_address,
      zip_code: String(data.address.zip_code),
      floor: data.address.floor,
      building: data.address.building,
      street: data.address.street,
      is_default: false,
      city: data.address.city,
      county: data.address.county
    }
    const patientLocationResponse = await patientAxios.post<IPatientAllData>(
      `/patient-location`,
      locationPayload
    )

    const emergencyPayload: any = {
      patient_id: patientId,
      first_name: data.emergency.first_name,
      middle_name: data.emergency.middle_name,
      last_name: data.emergency.last_name,
      id_number: data.emergency.id_number,
      passport_number: data.emergency.passport_number,
      mobile_number: data.emergency.mobile_number,
      email: data.emergency.email,
      next_of_kin: 'False'
    }
    const emergencyPayloadFormData = new FormData()

    Object.keys(emergencyPayload).forEach((key) => {
      emergencyPayloadFormData.append(key, emergencyPayload[key])
    })

    const patientEmergencyResponse = await patientAxios.post<IPatientAllData>(
      `/emergency-contact`,
      emergencyPayloadFormData
    )

    const lifeStylePayload = {
      patient_id: patientId,
      smoking: data.lifestyle.smoking,
      alcohol: data.lifestyle.alcohol,
      occupation: data.lifestyle.occupation,
      recreational_drugs: data.lifestyle.recreational_drugs
    }
    const patientLifeStyleResponse = await patientAxios.post<IPatientAllData>(
      `/patient-lifestyle`,
      lifeStylePayload
    )
    if (data.records.profile) {
      this.updateFile(data.records.profile, 'image', DOCUMENT_TYPE.image, patientId)
    }
    if (data.records.document1) {
      this.updateFile(data.records.document1, 'doc', DOCUMENT_TYPE.medicalRecord, patientId)
    }
    if (data.records.document2) {
      this.updateFile(data.records.document2, 'doc', DOCUMENT_TYPE.medicalRecord, patientId)
    }
    if (data.records.document3) {
      this.updateFile(data.records.document3, 'doc', DOCUMENT_TYPE.medicalRecord, patientId)
    }
    if (data.records.document4) {
      this.updateFile(data.records.document4, 'doc', DOCUMENT_TYPE.medicalRecord, patientId)
    }
    return true
  }
  refreshToken() {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const payload = {
          client_id: 'admin_dashboard',
          client_secret: '1ZjrIXglZ?2r?HofjA$2@Z3b'
        }
        const response = await patientAxios.post<IAuthToken>('/auth/login', payload)
        if (response.data.status === 'success') {
          setTokenPatient(response.data.access_token)
        }
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }
}
export default new PatientDataService()
