import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { keycloak } from './services/keycloak'
import { AppProvider } from './context'
import { ThemeProvider } from './providers'

keycloak
  .init({ onLoad: 'login-required' })
  .then((auth) => {
    if (!auth) {
      window.location.reload()
    }
    //React Render
    ReactDOM.render(
      <React.StrictMode>
        <ThemeProvider>
          <AppProvider>
            <App />
          </AppProvider>
        </ThemeProvider>
      </React.StrictMode>,
      document.getElementById('root')
    )
    // localStorage.setItem("react-token", keycloak.token);
    // localStorage.setItem("react-refresh-token", keycloak.refreshToken);
    setTimeout(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.debug('Token refreshed' + refreshed)
          } else {
            console.warn(
              'Token not refreshed, valid for ' + keycloak?.tokenParsed?.exp,
              keycloak?.timeSkew
            )
          }
        })
        .catch((error) => {
          console.error('Failed to refresh token', error)
        })
    }, 60000)
  })
  .catch((error) => {
    console.log('@error', error)
    console.error('Authenticated Failed')
  })
reportWebVitals()
