import { patientAxios, setTokenPatient } from './axios'
import { IPatientAllData, IPatientData, IAuthToken } from '../types/patient.type'
import {
  IStatusAllData,
  IGenderAllData,
  IMartialStatusAllData,
  IBloodGroupAllData
} from '../types/status.type'

class EpharmacyDataService {
  getAllPrescriptions(limit = 1000, offset = 0, filter?: any) {
    let params = {
      limit,
      offset
    }
    if (filter) params = { ...params, ...filter }
	
	  const dummy_data = {
      data: {
        offset: 0,
        limit: 10,
        returned_resultset_size: 3,
        available_resultset_size: 3,
        returned_resultset: [
          {id: '12345', patient_name: 'Kibugi Mbugua', medicines: 'Augmentin', doctor_name: 'Isaac Okipi', notes: 'Take after food'},
          {id: '5678', patient_name: 'Kamau Mbugua', medicines: 'Augmentin', doctor_name: 'Isaac Okipi', notes: 'Take after food'},
          {id: 'Aqy773', patient_name: 'Kamau Mbugua', medicines: 'Augmentin', doctor_name: 'Isaac Okipi', notes: 'Take after food'}
        ]
      }
    }
    return dummy_data
  }

  createPrescription(data: any) {
    // const response = patientAxios.post<Array<IPatientAllData>>(`/patient`, data)
    console.log('CREATE PRESCRIPTION DATA:', data)
    const dummy_response = {}
    return dummy_response
  }

  getAllVendors(limit = 1000, offset = 0, filter?: any) {
    let params = {
      limit,
      offset
    }
    if (filter) params = { ...params, ...filter }
	
	const dummy_data = {
      data: {
        offset: 0,
        limit: 10,
        returned_resultset_size: 3,
        available_resultset_size: 3,
        returned_resultset: [
          {id: '12345', name: 'Ram Pharmaceuticals', email: 'ram@email.como', phone: '254722789055'},
          {id: 'PH-99Ty', name: 'RandChem', email: 'range@email.como', phone: '254722789055'},
          {id: '6T770', name: 'Penda Health', email: 'penda@email.como', phone: '254722789055'},
        ]
      }
    }
    return dummy_data
  }

  createVendor(data: any) {
    // const response = patientAxios.post<Array<IPatientAllData>>(`/patient`, data)
    console.log('CREATE VENDOR DATA:', data)
    const dummy_response = {}
    return dummy_response
  }

  getAllMedicines(limit = 1000, offset = 0, filter?: any) {
    let params = {
      limit,
      offset
    }
    if (filter) params = { ...params, ...filter }
	
    const dummy_data = {
      data: {
        offset: 0,
        limit: 10,
        returned_resultset_size: 3,
        available_resultset_size: 3,
        returned_resultset: [
          {id: 'MD1234', medicine_name: 'Panadol', medicine_type: 'Pharmaceutical', medicine_unit: 'tablets', medicine_cost: '3000'},
          {id: 'MD5769', medicine_name: 'Ibuprofen', medicine_type: 'Pharmaceutical', medicine_unit: 'tablets', medicine_cost: '70'},
          {id: 'MD8907', medicine_name: 'Augmentin', medicine_type: 'Pharmaceutical', medicine_unit: 'tablets', medicine_cost: '450'},
        ]
      }
    }
    return dummy_data
  }

  createMedicine(data: any) {
    // const response = patientAxios.post<Array<IPatientAllData>>(`/patient`, data)
    console.log('CREATE MEDICINE DATA:', data)
    const dummy_response = {}
    return dummy_response
  }

  getAllOrders(limit = 1000, offset = 0, filter?: any) {
    let params = {
      limit,
      offset
    }
    if (filter) params = { ...params, ...filter }
	
    const dummy_data = {
      data: {
        offset: 0,
        limit: 10,
        returned_resultset_size: 3,
        available_resultset_size: 3,
        returned_resultset: [
          {id: 'MD123', description: 'Panadol', type: 'Urgent', invoice_id:'1234', prescription: '12345'},
          {id: 'MD5769', description: 'Ibuprofen', type: 'Normal', invoice_id:'1234', prescription: '5678'},
          {id: 'MD8907', description: 'Augmentin', type: 'Normal', invoice_id:'1234', prescription: '5678'},
          {id: 'MD8907', description: 'Augmentin', type: 'Urgent', invoice_id:'1234', prescription: 'Aqy773'},
        ]
      }
    }
    return dummy_data
  }

  createOrder(data: any) {
    // const response = patientAxios.post<Array<IPatientAllData>>(`/patient`, data)
    console.log('CREATE ORDER DATA:', data)
    const dummy_response = {}
    return dummy_response
  }

  getAllWidgets(limit = 1000, offset = 0, filter?: any) {
    let params = {
      limit,
      offset
    }
    if (filter) params = { ...params, ...filter }
	
      const dummy_data = {
        data: {
          offset: 0,
          limit: 10,
          returned_resultset_size: 3,
          available_resultset_size: 3,
          returned_resultset: [
            {id: 'MD1234', description: 'Panadol', origin: 'Urgent', url: '12345', title: 'MD1234'},
            {id: 'MD5769', description: 'Ibuprofen', origin: 'Normal', url: '5678', title: 'MD1234'},
            {id: 'MD8907', description: 'Augmentin', origin: 'Normal', url: '5678', title: 'MD1234'},
            {id: 'MD8907', description: 'Augmentin', origin: 'Urgent', url: 'Aqy773', title: 'MD1234'},
          ]
        }
      }
        return dummy_data
  }

  createWidget(data: any) {
    // const response = patientAxios.post<Array<IPatientAllData>>(`/patient`, data)
    console.log('CREATE WIDGET DATA:', data)
    const dummy_response = {}
    return dummy_response
  }

  getAllInventory(limit = 1000, offset = 0, filter?: any) {
    let params = {
      limit,
      offset
    }
    if (filter) params = { ...params, ...filter }
	
    const dummy_data = {
      data: {
        offset: 0,
        limit: 10,
        returned_resultset_size: 4,
        available_resultset_size: 4,
        returned_resultset: [
          {id: 'MD1234', medicine_name: 'Panadol', quantity: 'Urgent', medicine_type: '12345', title: 'MD1234'},
          {id: 'MD5769', medicine_name: 'Ibuprofen', quantity: 'Normal', medicine_type: '5678', title: 'MD1234'},
          {id: 'MD8907', medicine_name: 'Augmentin', quantity: 'Normal', medicine_type: '5678', title: 'MD1234'},
          {id: 'MD8907', medicine_name: 'Augmentin', quantity: 'Urgent', medicine_type: 'Aqy773', title: 'MD1234'},
        ]
      }
    }
    return dummy_data
  }

  createInventory(data: any) {
    // const response = patientAxios.post<Array<IPatientAllData>>(`/patient`, data)
    console.log('CREATE INVENTORY DATA:', data)
    const dummy_response = {}
    return dummy_response
  }

}
export default new EpharmacyDataService()
