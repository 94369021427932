import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router'

import { getNavItems } from '../constants'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './routes'
import FillLoader from '../components/FillLoader'
import PageNotFound from '../containers/PageNotFound'
import SideBar from '../components/SideBar'

import PublicRoute from './PublicRoute'
import { useAppContext } from '../context/AppProvider'
// import PrivateRoute from './PrivateRoute'

const Navigation = () => {
  const { workBooks } = useAppContext()

  const navItems = React.useMemo(() => {
    return getNavItems(workBooks)
  }, [workBooks])

  return (
    <Suspense fallback={<FillLoader />}>
      <Switch>
        {/* {PUBLIC_ROUTES.map((route) => {
          return <PublicRoute key={route.path} {...route} />
        })} */}
        <Route
          path="/"
          render={() => (
            <SideBar
              bg="brand.700"
              color="white"
              navItems={navItems}
              hoverColor="brand.500"
              accentColor="brand.50"
            >
              {PRIVATE_ROUTES.map((route) => {
                return <PublicRoute key={route.path} {...route} />
              })}
            </SideBar>
          )}
        />
        <Route render={() => <PageNotFound />} />
      </Switch>
    </Suspense>
  )
}

export default Navigation
