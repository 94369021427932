import React from 'react'

import { FlexProps, Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { space, SpaceProps } from 'styled-system'
import { User, IconProps } from 'react-feather'

import { theme } from '../../theme'
import { Text } from '../../typography'
import { MenuItem } from './styles'

export type BottomTabProps = FlexProps
type BottomContProps = SpaceProps
type BottomTabIconProps = FlexProps & {
  text: string
  to: string
  Icon: React.FC<IconProps>
}

const BottomCont = styled(motion.div)<BottomContProps>`
  ${space};
  bottom: 0;
  width: 100%;
  right: 0;
  height: 75px;
  z-index: 1290;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  box-shadow: 0 1px 1px 0 ${theme.colors.brand[700]};
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background-color: white;
`

const BottomTabItem: React.FC<BottomTabIconProps> = ({ text, to, Icon }) => (
  <MenuItem to={to} activeClassName="active-bottom-nav-link">
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Icon />
      <Text mt={2} fontSize="10px">
        {text}
      </Text>
    </Flex>
  </MenuItem>
)

const BottomTab: React.FC<BottomTabProps> = ({}) => {
  return (
    <BottomCont pl={2} pr={2}>
      <BottomTabItem Icon={User} text="Dashboard" to="/dashboard" />
      <BottomTabItem Icon={User} text="Customer" to="/customer" />
      <BottomTabItem Icon={User} text="Appointment" to="/appointment" />
      <BottomTabItem Icon={User} text="Services" to="/services" />
      <BottomTabItem Icon={User} text="Products" to="/products" />
      <BottomTabItem Icon={User} text="Enquiry" to="/enquiry" />
    </BottomCont>
  )
}

export default BottomTab
