import { serviceCatalogAxios } from './axios'
import {
  IFacilityAllData,
  IDoctorAllData,
  IAllDoctorGender,
  IGetDoctor,
  IGetPracticeType,
  IGetDoctorTypes,
  IGetOperationalStatus,
  IGetFacilityType,
  IGetFacilityStatus,
  IGetPackages,
  IGetStatus,
  IGetWorkBook,
  IGetProducts,
  IGetPackageCategory,
  IGetSpecialties,
  IGetDoctorAvailability,
  IGetDoctorStatus,
  IGetCounty,
  IGetFacility,
  IGetPackage,
  IGetProduct,
  IGetServiceByFacility,
  IGetPackageByFacility,
  IGetStatistics,
  IGetAllDoctorFacility,
  IGetAllFacilityServices,
  IGetAllFacilityPackage,
  IGetCategory,
  IGetFacilitySinglePackage,
  GetAllCategoriesByWorkBook,
  IGetAllAmenities,
  IQualification,
  IGetFaith,
  IGetAllInterests,
  IGetAllDoctorAdmittingRights,
  IGetAllDoctorAmenity,
  IGetAllDoctorVideo,
  IGetAllDoctorQualifications,
  IGetAllSubCategories
} from '../types/servicesCatalog.type'
import { DOCUMENT_TYPE } from '../constants'

import moment from 'moment'
import { Faq } from '../containers/AddProduct/Faqs'
import { AxiosResponse } from 'axios'

const DATE_FORMAT = 'YYYY-MM-DD'
class ServicesCatalogService {
  getAllFacility(limit = 1000, offset = 0, filter?: any) {
    let params = {
      limit,
      offset
    }
    if (filter) params = { ...params, ...filter }

    return serviceCatalogAxios.get<IFacilityAllData>('/facility', { params })
  }
  getServiceByFacility(facilityId: string) {
    return serviceCatalogAxios.get<IGetAllFacilityServices>(`/facility/${facilityId}?services=True`)
  }
  getServiceFacilityById(serviceFacilityId: string) {
    return serviceCatalogAxios.get<IGetServiceByFacility>(`/facility-service/${serviceFacilityId}`)
  }
  getPackageFacilityById(serviceFacilityId: string) {
    return serviceCatalogAxios.get<IGetFacilitySinglePackage>(
      `/facility-package/${serviceFacilityId}`
    )
  }
  getPackageByFacility(facilityId: string) {
    return serviceCatalogAxios.get<IGetAllFacilityPackage>(`/facility/${facilityId}?packages=True`)
  }
  getFee() {
    return serviceCatalogAxios.get<IGetPackageByFacility>('/fee')
  }
  getAllPackages(limit = 1000, offset = 0, filter?: any) {
    let params = {
      limit,
      offset
    }
    if (filter) params = { ...params, ...filter }

    return serviceCatalogAxios.get<IGetPackages>('/package/all', { params })
  }
  getAllDoctors(limit = 1000, offset = 0, filter?: any) {
    let params = {
      limit,
      offset
    }
    if (filter) params = { ...params, ...filter }

    return serviceCatalogAxios.get<Array<IDoctorAllData>>(`/doctor`, {
      params
    })
  }
  getPackage(id: string) {
    return serviceCatalogAxios.get<IGetPackage>(`/package/${id}`)
  }
  getAllProducts(limit = 1000, offset = 0, filter?: any) {
    let params = {
      limit,
      offset
    }
    if (filter) params = { ...params, ...filter }

    return serviceCatalogAxios.get<IGetProducts>(`/service/all`, {
      params
    })
  }
  getService(limit = 100, offset = 0, filter?: any) {
    let params = {
      limit,
      offset
    }
    if (filter) params = { ...params, ...filter }

    return serviceCatalogAxios.get<IGetProducts>(`/service`, {
      params
    })
  }
  getDoctor(id: string) {
    return serviceCatalogAxios.get<IGetDoctor>(`/doctor/${id}`)
  }
  getDoctorInterest(id: string): Promise<Partial<AxiosResponse<IGetAllInterests, any>>> {
    return new Promise(async (res) => {
      try {
        const response = await serviceCatalogAxios.get<IGetAllInterests>(`/doctor-interest`, {
          params: {
            doctor_id: id
          }
        })
        res(response)
      } catch (error) {
        res({
          data: {
            available_resultset_size: 0,
            returned_resultset_size: 0,
            returned_resultset: []
          }
        })
      }
    })
  }
  getDoctorAdmittingRights(
    id: string
  ): Promise<Partial<AxiosResponse<IGetAllDoctorAdmittingRights, any>>> {
    return new Promise(async (res) => {
      try {
        const response = await serviceCatalogAxios.get<IGetAllDoctorAdmittingRights>(
          `/admitting-right`,
          {
            params: {
              doctor_id: id
            }
          }
        )
        res(response)
      } catch (error) {
        res({
          data: {
            available_resultset_size: 0,
            returned_resultset_size: 0,
            returned_resultset: []
          }
        })
      }
    })
  }
  getDoctorAmenities(id: string): Promise<Partial<AxiosResponse<IGetAllDoctorAmenity, any>>> {
    return new Promise(async (res) => {
      try {
        const response = await serviceCatalogAxios.get<IGetAllDoctorAmenity>(`/doctor-amenities`, {
          params: {
            doctor_id: id
          }
        })
        res(response)
      } catch (error) {
        res({
          data: {
            offset: 0,
            limit: 0,
            returned_resultset: [],
            returned_resultset_size: 0,
            available_resultset_size: 0
          }
        })
      }
    })
  }
  getDoctorQualification(
    id: string
  ): Promise<Partial<AxiosResponse<IGetAllDoctorQualifications, any>>> {
    return new Promise(async (res) => {
      try {
        const response = await serviceCatalogAxios.get<IGetAllDoctorQualifications>(
          `/doctor-qualification`,
          {
            params: {
              doctor_id: id
            }
          }
        )
        res(response)
      } catch (error) {
        res({
          data: {
            offset: 0,
            limit: 0,
            returned_resultset: [],
            returned_resultset_size: 0,
            available_resultset_size: 0
          }
        })
      }
    })
  }
  getDoctorVideo(id: string): Promise<Partial<AxiosResponse<IGetAllDoctorVideo, any>>> {
    return new Promise(async (res) => {
      try {
        const response = await serviceCatalogAxios.get<IGetAllDoctorVideo>(`/doctor-video`, {
          params: {
            doctor_id: id
          }
        })
        res(response)
      } catch (error) {
        res({
          data: {
            offset: 0,
            limit: 0,
            returned_resultset: [],
            returned_resultset_size: 0,
            available_resultset_size: 0
          }
        })
      }
    })
  }
  getFacility(id: string) {
    return serviceCatalogAxios.get<IGetFacility>(`/facility/${id}`)
  }
  getProduct(id: string) {
    return serviceCatalogAxios.get<IGetProduct>(`/service/${id}`)
  }
  createFacilityService(payload: any) {
    return serviceCatalogAxios.post<IGetProduct>(`/facility-service`, payload)
  }
  createFacilityPackage(payload: any) {
    return serviceCatalogAxios.post<IGetProduct>(`/facility-package`, payload)
  }
  updateFacilityPackage(id: string, payload: any) {
    return serviceCatalogAxios.put<IGetProduct>(`/facility-package/${id}`, payload)
  }
  getOperationalStatus() {
    return serviceCatalogAxios.get<IGetOperationalStatus>(`/operational-status`)
  }
  getPackageCategory() {
    return serviceCatalogAxios.get<IGetPackageCategory>(`/package-category`)
  }
  getCategoryByWorkBook(workbookId: string) {
    return serviceCatalogAxios.get<GetAllCategoriesByWorkBook>(`/category`, {
      params: {
        workbook_id: workbookId
      }
    })
  }
  getSubCategory(id: string) {
    return serviceCatalogAxios.get<IGetAllSubCategories>(`/category/${id}`)
  }
  getStatus() {
    return serviceCatalogAxios.get<IGetStatus>(`/status`)
  }
  getWorkBook() {
    return serviceCatalogAxios.get<IGetWorkBook>(`/workbook`)
  }
  getDoctorType() {
    return serviceCatalogAxios.get<IGetDoctorTypes>(`/doctor-type`)
  }
  getSpecialties() {
    return serviceCatalogAxios.get<IGetSpecialties>(`/speciality?limit=200`)
  }
  getAmenities() {
    return serviceCatalogAxios.get<IGetAllAmenities>(`/amenities`)
  }
  getDoctorStatus() {
    return serviceCatalogAxios.get<IGetDoctorStatus>(`/doctor-status`)
  }
  getDoctorAvailabilityType() {
    return serviceCatalogAxios.get<IGetDoctorAvailability>(`/availability-type`)
  }
  getCountry() {
    return serviceCatalogAxios.get<IGetCounty>(`/county`)
  }
  getCategory() {
    return serviceCatalogAxios.get<IGetCategory>(`/category`)
  }
  getSubCountry() {
    return serviceCatalogAxios.get<IGetCounty>(`/sub-county`)
  }
  getWard() {
    return serviceCatalogAxios.get<IGetCounty>(`/ward`)
  }
  getDoctorGender() {
    return serviceCatalogAxios.get<Array<IAllDoctorGender>>('/gender')
  }
  getFacilityType() {
    return serviceCatalogAxios.get<IGetFacilityType>('/facility-type')
  }
  getFaiths() {
    return serviceCatalogAxios.get<IGetFaith>('/faith')
  }
  getFacilityStatus() {
    return serviceCatalogAxios.get<IGetFacilityStatus>('/facility-status')
  }
  getDoctorPracticeType() {
    return serviceCatalogAxios.get<Array<IGetPracticeType>>('/practice-type')
  }
  getDoctorLicence() {
    return serviceCatalogAxios.get<Array<IGetPracticeType>>('/licence-type')
  }
  getDoctorFacility(facilityId: string) {
    return serviceCatalogAxios.get<IGetAllDoctorFacility>(`/facility/${facilityId}?doctors=True`)
  }
  getServicesByFacility(facilityId: string, limit = 1000, offset = 0) {
    let params = {
      limit,
      offset,
      facility_id: facilityId
    }

    return serviceCatalogAxios.get<IGetServiceByFacility>(`/facility-service`, {
      params
    })
  }
  getPackagesByFacility(facilityId: string, limit = 1000, offset = 0) {
    let params = {
      limit,
      offset,
      facility_id: facilityId
    }

    return serviceCatalogAxios.get<IGetFacilitySinglePackage>(`/facility-package`, {
      params
    })
  }
  updateDoctorStatus(id: string, payload: any) {
    return serviceCatalogAxios.put<Array<IAllDoctorGender>>(`/doctor/${id}`, payload)
  }
  async createPackage(values: any) {
    const payload = {
      name: values.name,
      keywords: values.keywords,
      is_private: values.is_private,
      seo: values.seo,
      package_category_id: values.package_category_id,
      status_id: values.status_id,
      notes: values.notes
    }

    const response = await serviceCatalogAxios.post<IAllDoctorGender>(`/package`, payload)
    const id = response.data.returned_resultset[0].id

    if (values.image) {
      await this.updatePackageImage(values.image, DOCUMENT_TYPE.productImage, id)
    }
  }
  async updatePackage(id: string, values: any) {
    const payload = {
      name: values.name,
      keywords: values.keywords,
      is_private: values.is_private,
      seo: values.seo,
      package_category_id: values.package_category_id,
      status_id: values.status_id
    }

    await serviceCatalogAxios.put<Array<IAllDoctorGender>>(`/package/${id}`, payload)

    if (typeof values.image === 'object') {
      await this.updatePackageImage(values.image, DOCUMENT_TYPE.productImage, id)
    }
  }
  async createService(values: any, selectedWorkBook?: string, faqs?: Faq[]) {
    const payload: any = {
      title: values.title,
      cpt_code: values.cpt_code,
      local_code: values.local_code,
      cpt_description: values.cpt_description,
      workbook_id: values.workbook_id,
      description: values.description,
      min_price: values.min_price,
      max_price: values.max_price,
      // image_url: values.image_url,
      requester: values.requester,
      collection: values.collection,
      seo: values.seo,
      tat: values.tat,
      notes: values.notes,
      landing_page: values.landing_page,
      status_id: values.status_id,
      category_id: values.category_id,
      is_product: values.is_product
    }
    if (selectedWorkBook === 'Labs') {
      payload.indication = values.indication
      payload.preparation = values.preparation
      payload.sample = values.sample
      payload.icd_ten = values.icd_ten
    }

    if (selectedWorkBook === 'Radiology') {
      payload.contraindications = values.contraindications
      payload.modality = values.modality
      payload.indication = values.indication
      payload.preparation = values.preparation
      payload.icd_ten = values.icd_ten
    }

    const response = await serviceCatalogAxios.post<IAllDoctorGender>(`/service`, payload)
    const id = response.data.returned_resultset[0].id

    if (typeof values.image === 'object') {
      await this.updateServiceImage(values.image, DOCUMENT_TYPE.productImage, id)
    }

    const createFaqs =
      faqs?.filter((row) => row.status === 'created' && row.answer && row.question) || []

    for (const iterator of createFaqs) {
      const payload = {
        service_id: id,
        question: iterator.question,
        answer: iterator.answer
      }
      serviceCatalogAxios.post<any>('/faq', payload)
    }
  }
  updateService(id: string, payload: any) {
    return serviceCatalogAxios.put<Array<IAllDoctorGender>>(`/service/${id}`, payload)
  }
  createFacility(payload: any) {
    return serviceCatalogAxios.post<Array<IAllDoctorGender>>(`/facility`, payload)
  }
  updateFacility(id: string, payload: any) {
    return serviceCatalogAxios.put<IAllDoctorGender>(`/facility/${id}`, payload)
  }
  async addDoctor(params: any) {
    const doctorPayload = {
      name: params.basic.firstName + ' ' + params.basic.middleName + ' ' + params.basic.lastName,
      gender_id: params.basic.gender_id,
      email: params.basic.email,
      mobile_number: params.basic.mobile_number,
      speciality_id: params.basic.speciality_id,
      reg_no: params.basic.reg_no,
      doctor_type_id: params.basic.doctor_type_id,
      doctor_status_id: params.basic.doctor_status_id,
      is_promoted: params.basic.is_promoted,
      location: params.basic.location,
      latitude: params.basic.latitude,
      longitude: params.basic.longitude,
      county_id: params.basic.county_id,
      sub_county_id: params.basic.sub_county_id,
      ward_id: params.basic.ward_id,

      facility_rate: params.additionalInfo.facility_rate,
      home_rate: params.additionalInfo.home_rate,
      teleconsult_rate: params.additionalInfo.teleconsult_rate,
      rake: params.additionalInfo.rake,
      emergency_rate: params.additionalInfo.emergency_rate,
      availability_status: params.additionalInfo.availability_status,
      wait_time: params.additionalInfo.wait_time,
      faith_id: params.additionalInfo.faith_id,
      rast: params.additionalInfo.rast,
      assistant_name: params.additionalInfo.assistant_name,
      assistant_phone: params.additionalInfo.assistant_phone,
      assistant_email: params.additionalInfo.assistant_email,
      express: params.additionalInfo.express
    }

    const response = await serviceCatalogAxios.post('/doctor', doctorPayload)
    const doctorId = response.data.returned_resultset[0].id

    Object.keys(params.schedule).map((key) => {
      const payload = {
        doctor_id: doctorId,
        day: key,
        open_time: params.schedule[key].open_time,
        close_time: params.schedule[key].close_time,
        is_open: params.schedule[key].is_open
      }
      serviceCatalogAxios.post('/doctor-schedule', payload)
    })

    params.availability.forEach((ele: any) => {
      const payload = {
        doctor_id: doctorId,
        availability_type_id: ele.availability_type_id,
        is_available: ele.is_available
      }
      serviceCatalogAxios.post('/doctor-availability', payload)
    })
    params.clinic.forEach((ele: any) => {
      const payload = {
        doctor_id: doctorId,
        facility_id: ele
      }
      serviceCatalogAxios.post('/facility-doctor', payload)
    })

    params?.qualifications?.forEach((ele: IQualification) => {
      const payload = {
        doctor_id: doctorId,
        qualification: ele.qualification,
        university: ele.university,
        year: ele.year
      }
      serviceCatalogAxios.post('/doctor-qualification', payload)
    })

    if (params?.additionalInfo?.interest) {
      const payload = {
        doctor_id: doctorId,
        interest: params.additionalInfo.interest
      }
      serviceCatalogAxios.post('/doctor-interest', payload)
    }

    if (params?.additionalInfo?.admitting_right) {
      const payload = {
        doctor_id: doctorId,
        facilities: [params.additionalInfo.admitting_right]
      }
      serviceCatalogAxios.post('/admitting-right', payload)
    }

    if (params?.additionalInfo?.amenity_id) {
      const payload = {
        doctor_id: doctorId,
        amenity_ids: [params.additionalInfo.amenity_id]
      }
      serviceCatalogAxios.post('/doctor-amenities', payload)
    }

    if (params?.additionalInfo?.video_url) {
      const payload = {
        doctor_id: doctorId,
        title: 'A sample title',
        video_url: params?.additionalInfo?.video_url
      }
      serviceCatalogAxios.post('/doctor-video', payload)
    }

    if (params.records.profile) {
      this.updateDoctorImage(params.records.profile, DOCUMENT_TYPE.doctorImage, doctorId)
    }
  }
  async updateDoctor(params: any, id: string, formValue: any) {
    console.log('@params', params, formValue)

    const doctorPayload: any = {}
    if (params?.basic?.name) doctorPayload.name = params.basic.name
    if (params?.basic?.gender_id) doctorPayload.gender_id = params.basic.gender_id
    if (params?.basic?.email) doctorPayload.email = params.basic.email
    if (params?.basic?.mobile_number) doctorPayload.mobile_number = params.basic.mobile_number
    if (params?.basic?.speciality_id) doctorPayload.speciality_id = params.basic.speciality_id
    if (params?.basic?.reg_no) doctorPayload.reg_no = params.basic.reg_no
    if (params?.basic?.doctor_type_id) doctorPayload.doctor_type_id = params.basic.doctor_type_id
    if (params?.basic?.doctor_status_id)
      doctorPayload.doctor_status_id = params.basic.doctor_status_id
    if (params?.basic?.is_promoted) doctorPayload.is_promoted = params.basic.is_promoted
    if (params?.basic?.location) doctorPayload.location = params.basic.location
    if (params?.basic?.latitude) doctorPayload.latitude = params.basic.latitude
    if (params?.basic?.longitude) doctorPayload.longitude = params.basic.longitude
    if (params?.basic?.county_id) doctorPayload.county_id = params.basic.county_id
    if (params?.basic?.sub_county_id) doctorPayload.sub_county_id = params.basic.sub_county_id
    if (params?.basic?.ward_id) doctorPayload.ward_id = params.basic.ward_id

    if (params?.additionalInfo?.facility_rate)
      doctorPayload.facility_rate = params.additionalInfo.facility_rate
    if (params?.additionalInfo?.home_rate) doctorPayload.home_rate = params.additionalInfo.home_rate
    if (params?.additionalInfo?.teleconsult_rate)
      doctorPayload.teleconsult_rate = params.additionalInfo.teleconsult_rate
    if (params?.additionalInfo?.rake) doctorPayload.rake = params.additionalInfo.rake
    if (params?.additionalInfo?.emergency_rate)
      doctorPayload.emergency_rate = params.additionalInfo.emergency_rate
    if (params?.additionalInfo?.availability_status)
      doctorPayload.availability_status = params.additionalInfo.availability_status
    if (params?.additionalInfo?.wait_time) doctorPayload.wait_time = params.additionalInfo.wait_time
    if (params?.additionalInfo?.faith_id) doctorPayload.faith_id = params.additionalInfo.faith_id
    if (params?.additionalInfo?.rast) doctorPayload.rast = params.additionalInfo.rast
    if (params?.additionalInfo?.assistant_name)
      doctorPayload.assistant_name = params.additionalInfo.assistant_name
    if (params?.additionalInfo?.assistant_phone)
      doctorPayload.assistant_phone = params.additionalInfo.assistant_phone
    if (params?.additionalInfo?.assistant_email)
      doctorPayload.assistant_email = params.additionalInfo.assistant_email
    if (params?.additionalInfo?.express) doctorPayload.express = params.additionalInfo.express

    await serviceCatalogAxios.put(`/doctor/${id}`, doctorPayload)

    Object.keys(params.schedule).map((key) => {
      if (params.schedule[key]) {
        const payload = {
          day: key,
          open_time: params.schedule[key].open_time,
          close_time: params.schedule[key].close_time,
          is_open: params.schedule[key].is_open
        }
        serviceCatalogAxios.put(`/doctor-schedule/${params.schedule[key].id}`, payload)
      }
    })

    params.availability.forEach((ele: any) => {
      const payload: any = {
        availability_type_id: ele.availability_type_id,
        is_available: ele.is_available
      }
      if (ele.id) {
        serviceCatalogAxios.put(`/doctor-availability/${ele.id}`, payload)
      } else {
        payload.doctor_id = id
        serviceCatalogAxios.post('/doctor-availability', payload)
      }
    })

    params.facilities.forEach((ele: any) => {
      const payload = {
        doctor_id: id,
        facility_id: ele
      }
      serviceCatalogAxios.post('/facility-doctor', payload)
    })

    if (params?.additionalInfo?.admitting_right) {
      // array.forEach(element => {
      //   serviceCatalogAxios.delete(`/admitting-right/${formValue.admissions?.[0]?.id}`)
      // });
      const payload = {
        doctor_id: id,
        facilities: [params.additionalInfo.admitting_right]
      }
      serviceCatalogAxios.post('/admitting-right', payload)
    }

    if (params?.additionalInfo?.amenity_id) {
      formValue?.amenities?.forEach((element: { id: any }) => {
        serviceCatalogAxios.delete(`/doctor-amenities/${element?.id}`)
      })
      const payload = {
        doctor_id: id,
        amenity_ids: [params.additionalInfo.amenity_id]
      }
      serviceCatalogAxios.post('/doctor-amenities', payload)
    }

    if (params.records.profile) {
      this.updateDoctorImage(params.records.profile, DOCUMENT_TYPE.doctorImage, id)
    }
  }

  getStatistic(start_date: string, end_date: string) {
    const params = {
      start_date: moment(start_date, DATE_FORMAT).toISOString(),
      end_date: moment(end_date, DATE_FORMAT).toISOString()
    }
    return serviceCatalogAxios.get<IGetStatistics>('/statistics', {
      params
    })
  }
  async updateDoctorImage(files: File, documentType: number | string, id: string) {
    try {
      const payload = new FormData()
      payload.append('document_type_id', String(documentType))

      payload.append('profile_image', files)

      await serviceCatalogAxios.put<{}>(`/doctor/${id}`, payload)
    } catch (error) {
      console.log('Error')
      return error
    }
  }
  async updatePackageImage(files: File, documentType: number | string, id: string) {
    try {
      const payload = new FormData()
      payload.append('document_type_id', String(documentType))

      payload.append('item_image', files)

      await serviceCatalogAxios.put<{}>(`/package/${id}`, payload)
    } catch (error) {
      console.log('Error')
      return error
    }
  }
  async updateServiceImage(files: File, documentType: number | string, id: string) {
    try {
      const payload = new FormData()
      payload.append('document_type_id', String(documentType))

      payload.append('item_image', files)

      await serviceCatalogAxios.put<{}>(`/service/${id}`, payload)
    } catch (error) {
      console.log('Error')
      return error
    }
  }
}
export default new ServicesCatalogService()
