export default {
  appointment: require('../assets/images/appointment.svg'),
  customer: require('../assets/images/customer.svg'),
  dashboard: require('../assets/images/dashboard.svg'),
  enquiry: require('../assets/images/enquiry.svg'),
  loginBanner: require('../assets/images/login-banner.png'),
  mailBox: require('../assets/images/mailbox.svg'),
  poneaLogo: require('../assets/images/ponea-logo.png'),
  service: require('../assets/images/service.svg')
}
