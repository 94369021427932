import * as React from 'react'
import {
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement
} from '@chakra-ui/core'
import { InputProps } from '@chakra-ui/core/dist/Input'
import { useField } from 'formik'

import { LabelProps } from '../styles'
import { Text } from '../../../typography'
import { XCircle } from 'react-feather'

export type ConnectedFormGroupProps = LabelProps &
  InputProps & {
    label?: string
    name: string
    icon?: React.FC
    iconPosition?: string
    iconColor?: string
    onIconClick?: () => void
    onClearIconClick?: () => void
    clearIcon?: boolean
  }

const ConnectedFormGroup: React.FC<ConnectedFormGroupProps> = ({
  label,
  icon,
  iconPosition,
  iconColor,
  onIconClick,
  clearIcon,
  onClearIconClick,
  ...rest
}) => {
  const [field, meta] = useField(rest.name)

  return (
    <Flex
      flexDirection="column"
      position="relative"
      width="100%"
      mr={rest.mr}
      ml={rest.ml}
      mt={rest.mt}
      mb={rest.mb}
    >
      <FormControl>
        {label && (
          <FormLabel
            style={{
              position: 'absolute',
              zIndex: '1',
              backgroundColor: 'white',
              top: '-1rem',
              left: '1rem',
              paddingLeft: '0.3rem'
            }}
            htmlFor={field.name}
          >
            {label}
          </FormLabel>
        )}
        <InputGroup size="md" flexDirection="row-reverse">
          <Input
            borderColor={meta.touched ? (meta.error ? 'red.500' : 'success.400') : 'brand.200'}
            focusBorderColor="accent.500"
            pl={iconPosition === 'left' ? 10 : 0}
            pr={iconPosition === 'right' ? 10 : 0}
            {...field}
            id={field.name}
            {...rest}
          />

          {icon &&
            (iconPosition === 'left' ? (
              <InputLeftElement>
                {clearIcon && (
                  <Icon
                    size="15px"
                    onClick={() => {
                      if (onClearIconClick) onClearIconClick()
                      return
                    }}
                    as={XCircle}
                    color="red"
                  />
                )}

                <Icon
                  size="15px"
                  onClick={() => {
                    if (onIconClick) onIconClick()
                    return
                  }}
                  as={icon}
                  color={iconColor ? iconColor : 'primary.400'}
                />
              </InputLeftElement>
            ) : (
              <InputRightElement>
                {clearIcon && (
                  <Icon
                    size="15px"
                    onClick={() => {
                      if (onClearIconClick) onClearIconClick()
                      return
                    }}
                    as={XCircle}
                    color="red"
                  />
                )}

                <Icon
                  size="15px"
                  onClick={() => {
                    if (onIconClick) onIconClick()
                    return
                  }}
                  as={icon}
                  color={iconColor ? iconColor : 'primary.400'}
                />
              </InputRightElement>
            ))}
        </InputGroup>
        {meta.touched && meta.error ? (
          <Text color="red.500" textAlign="right">
            {meta.error}
          </Text>
        ) : null}
      </FormControl>
    </Flex>
  )
}

export default ConnectedFormGroup

ConnectedFormGroup.defaultProps = {
  mb: 2,
  type: 'text'
}
