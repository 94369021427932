import axios, { AxiosRequestConfig } from 'axios'
import {
  PATIENT_BASE_URL,
  APPOINTMENT_BASE_URL,
  SERVICES_CATALOG_BASE_URL,
  CALL_CENTER_BASE_URL,
  MOC_BASE_URL,
  KEY_CLOAK_BASE_URL
} from '../constants'

const defaultAxiosParams: AxiosRequestConfig = {
  headers: {
    'Content-type': 'application/json'
  }
}

export const patientAxios = axios.create({ ...defaultAxiosParams, baseURL: PATIENT_BASE_URL })

export const keylockAxios = axios.create({ baseURL: KEY_CLOAK_BASE_URL })

export const mocAxios = axios.create({ ...defaultAxiosParams, baseURL: MOC_BASE_URL })

export const serviceCatalogAxios = axios.create({
  ...defaultAxiosParams,
  baseURL: SERVICES_CATALOG_BASE_URL
})

export const appointmentAxios = axios.create({
  ...defaultAxiosParams,
  baseURL: APPOINTMENT_BASE_URL
})

export const callCenterAxios = axios.create({
  headers: {
    'Content-type': 'application/json',
    Authorization: `Token ${process.env.REACT_APP_KEY_API_TOKEN_CALL_CENTER}`
  },
  baseURL: CALL_CENTER_BASE_URL
})

export const setTokenPatient = (token: string) => {
  patientAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const setTokenKeyclock = (token: string) => {
  keylockAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const setTokenMOC = (token: string, id: string) => {
  mocAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  mocAxios.defaults.headers.common['user'] = id
}

export const setTokenAppointment = (token: string) => {
  appointmentAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}
export const setTokenServiceCatalog = (token: string) => {
  serviceCatalogAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const setTokenCallCenter = (token: string) => {
  serviceCatalogAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}
